<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8824 5H6.11762C4.94809 5 4 5.90536 4 7.02218V15.9778C4 17.0946 4.94809 18 6.11762 18H17.8824C19.0519 18 20 17.0946 20 15.9778V15H15C14 15 13 14 13 13V10C13 9 14 8 15 8H20V7.02218C20 5.90536 19.0519 5 17.8824 5Z"
      class="app-fill-current"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.6579 9H19.3421C20.2577 9 21 9.63526 21 10.4189V12.5811C21 13.3647 20.2577 14 19.3421 14H15.6579C14.7423 14 14 13.3647 14 12.5811V10.4189C14 9.63526 14.7423 9 15.6579 9ZM15 11.5C15 10 16.5 10 16.5 10C16.5 10 18 10 18 11.5C18 13 16.5 13 16.5 13C16.5 13 15 13 15 11.5Z"
      class="app-fill-current"
    />
  </svg>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'icon-expenses',
});
</script>
