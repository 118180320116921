import store from '@/store';

export function authGuard(to, from, next) {
  const accessToken = store.getters.accessToken;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    //user not logged in
    if (!accessToken) {
      store.dispatch('clear_session');
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
}
