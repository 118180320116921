import IconSignOut from '@/common/icons/icon-sign-out';
import IconDashboard from '@/common/icons/icon-dashboard';
import IconProducts from '@/common/icons/icon-products';
import IconExpenses from '@/common/icons/icon-expenses';
import IconSettings from '@/common/icons/icon-settings';
import IconProfitDashboard from '@/common/icons/icon-profit-dashboard';
import IconProfitCalculator from '@/common/icons/icon-profit-calculator';
import IconFbaInventory from '@/common/icons/icon-fba-inventory';
import IconTrafficAnalytics from '@/common/icons/icon-traffic-analytics';
import IconProductsAndCogs from '@/common/icons/icon-products-and-cogs';
import IconCustomCategories from '@/common/icons/icon-custom-categories';
import IconManualExpenses from '@/common/icons/icon-manual-expenses';
import IconAdvertisingInsights from '@/common/icons/icon-advertising-insights';
import IconCustomReport from '@/common/icons/icon-custom-report';
import IconDayToDay from '@/common/icons/icon-day-to-day';
import IconMarketShare from '@/common/icons/icon-market-share';
import IconProfitAnalysis from '@/common/icons/icon-profit-analysis';

export const customIcons = {
  profitDashboard: {
    component: IconProfitDashboard,
  },
  profitCalculator: {
    component: IconProfitCalculator,
  },
  fbaInventory: {
    component: IconFbaInventory,
  },
  trafficAnalytics: {
    component: IconTrafficAnalytics,
  },
  productsAndCogs: {
    component: IconProductsAndCogs,
  },
  customCategories: {
    component: IconCustomCategories,
  },
  manualExpenses: {
    component: IconManualExpenses,
  },
  advertisingInsights: {
    component: IconAdvertisingInsights,
  },
  customReport: {
    component: IconCustomReport,
  },
  dayToDay: {
    component: IconDayToDay,
  },
  marketShare: {
    component: IconMarketShare,
  },
  profitAnalysis: {
    component: IconProfitAnalysis,
  },
  dashboard: {
    component: IconDashboard,
  },
  products: {
    component: IconProducts,
  },
  expenses: {
    component: IconExpenses,
  },
  settings: {
    component: IconSettings,
  },
  signOut: {
    component: IconSignOut,
  },
};
