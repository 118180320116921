import { fetch_sales_profit_chart } from '@/api/fetcher';
import { CANCELLED_MESSAGE } from '@/api/api';

const defaultState = () => ({
  data: [],
  rangeType: 'HOURLY', // DAILY, WEEKLY, HOURLY
  status: null, // success, error, loading
  error: null,
  filters: {
    skus: [],
    includeUnitemised: true,
  },
});

const state = defaultState();

const getters = {
  data: state => state.data,
  rangeType: state => state.rangeType,
  isLoading: state => state.status === 'loading',
  isError: state => state.status === 'error',
  isSuccess: state => state.status === 'success',
  status: state => state.status,
  filters: state => state.filters,
  includeUnitemised: state => state.filters.includeUnitemised,
};

const mutations = {
  loading(state) {
    state.status = 'loading';
    state.error = null;
    state.data = [];
  },
  success(state, { rangeType, data }) {
    state.rangeType = rangeType;
    state.data = data;
    state.status = 'success';
  },
  failure(state, { error }) {
    state.error = error;
    state.status = 'error';
  },
  setFilters(state, { skus, includeUnitemised }) {
    state.filters = {
      skus: skus || [],
      includeUnitemised: !!includeUnitemised,
    };
  },
  reset(state) {
    Object.assign(state, defaultState());
  },
};

const actions = {
  async get(
    { commit, state },
    { skus, includeUnitemised } = {
      skus: state.filters.skus,
      includeUnitemised: state.filters.includeUnitemised,
    }
  ) {
    commit('loading');

    commit('setFilters', { skus, includeUnitemised });

    await fetch_sales_profit_chart({
      skus,
      includeUnitemised,
    })
      .then(({ data }) => {
        const { rangeType, profitList } = data;

        commit('success', {
          rangeType,
          data: profitList,
        });
      })
      .catch(error => {
        if (error.message === CANCELLED_MESSAGE) {
          return;
        }
        commit('failure', { error: null });
      });
  },
  reset({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
