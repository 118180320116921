import Vue from 'vue';
import { PublicClientApplication } from '@azure/msal-browser';

const msal = new PublicClientApplication({
  auth: {
    clientId: '4f92ea6f-f7ca-4840-9a6c-034d8dadf5c2',
    authority: 'https://login.microsoftonline.com/common/',
    redirectUri: `${window.location.origin}/msal-redirect`,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
});

Vue.prototype.$msal = msal;
