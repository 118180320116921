<template>
  <div class="space-divider" :style="computedStyles"></div>
</template>

<script>
export default {
  name: 'HorizontalSpaceDivider',
  props: ['config'],
  data() {
    return {
      // default styles
      width: '32px',
    };
  },
  created() {
    this.setConfigData();
  },
  methods: {
    setConfigData() {
      if (this.config) {
        if (this.config.width) {
          this.width = this.config.width;
        }
      }
    },
  },
  computed: {
    computedStyles: function() {
      return [
        {
          width: this.width,
        },
      ];
    },
  },
};
</script>

<style scoped></style>
