import { TrafficAnalyticsApi } from '@/api/traffic-analytics';
import { mapRangeTypeToDatesIsoString } from '@/modules/DashboardLayout/TrafficAnalytics/date-ranges';

export function getCurrentRangeDates(rangeType, customDates) {
  if (rangeType === 'custom') {
    return customDates;
  }

  return mapRangeTypeToDatesIsoString(rangeType);
}

const defaultState = () => ({
  data: {
    conversionRate: {
      total: 0,
      organic: 0,
      ppc: 0,
    },
    sessions: {
      total: 0,
      organic: 0,
      paid: 0,
    },
    sales: {
      total: 0,
      organic: 0,
      ads: 0,
    },
    unitsSold: {
      total: 0,
      coupons: 0,
      fullPrice: 0,
    },
  },
  range: {
    type: 'last_7_days',
    dates: {
      start: mapRangeTypeToDatesIsoString('last_7_days').start,
      end: mapRangeTypeToDatesIsoString('last_7_days').end,
    },
  },
  status: null, // success, error, loading
  error: null,
  products: {
    data: [],
    status: null, // success, error, loading
    error: null,
    groupBy: 'PARENT',
  },
  chart: {
    data: [],
    skus: [],
    status: null, // success, error, loading
    error: null,
  },
});

const state = defaultState();

const getters = {
  data: state => state.data,
  range: state => state.range,
  rangeType: state => state.range.type,
  rangeDates: state => state.range.dates,
  isLoading: state => state.status === 'loading',
  isError: state => state.status === 'error',
  isSuccess: state => state.status === 'success',
  status: state => state.status,
  filters: state => state.filters,

  conversionRate: state => state.data.conversionRate,
  sessions: state => state.data.sessions,
  sales: state => state.data.sales,
  unitsSold: state => state.data.unitsSold,

  products: state => state.products,
  productsData: state => state.products.data,
  productsIsLoading: state => state.products.status === 'loading',
  productsIsError: state => state.products.status === 'error',
  productsGroupBy: state => state.products.groupBy || 'asin',

  chart: state => state.chart,
  chartData: state => state.chart.data,
  chartIsLoading: state => state.chart.status === 'loading',
  chartIsError: state => state.chart.status === 'error',
  chartSkus: state => state.chart.skus,
};

const mutations = {
  setRange(state, { type, dates }) {
    state.range = {
      type,
      dates,
    };
  },

  loading(state) {
    state.status = 'loading';
    state.error = null;
  },
  success(state, { data }) {
    state.data = data;
    state.status = 'success';
  },
  failure(state, { error }) {
    state.data = defaultState().data;
    state.error = error;
    state.status = 'error';
  },

  chartLoading(state) {
    state.chart.status = 'loading';
    state.chart.error = null;
  },
  chartSuccess(state, { data }) {
    state.chart.data = data;
    state.chart.status = 'success';
  },
  chartFailure(state, { error }) {
    state.data = defaultState().chart.data;
    state.chart.error = error;
    state.chart.status = 'error';
  },
  setChartSkus(state, { skus }) {
    state.chart.skus = skus;
  },

  productsLoading(state) {
    state.products.status = 'loading';
    state.products.error = null;
  },
  productsSuccess(state, { data }) {
    state.products.data = data;
    state.products.status = 'success';
  },
  productsFailure(state, { error }) {
    state.data = defaultState().products.data;
    state.products.error = error;
    state.products.status = 'error';
  },
  setProductsGroupBy(state, { groupBy }) {
    state.products.groupBy = groupBy;
  },
};

const actions = {
  async get({ commit, state }, { range } = { range: state.range }) {
    commit('loading');

    const dates = getCurrentRangeDates(range.type, range.dates);

    await TrafficAnalyticsApi.get({ dates })
      .then(res => {
        commit('success', {
          data: res,
        });
      })
      .catch(error => {
        commit('failure', { error: error });
      });
  },
  async getChart(
    { commit, state },
    { skus, allProducts } = { skus: state.chart.skus, allProducts: [] }
  ) {
    commit('chartLoading');

    const productAsinBySkuMap = new Map();

    allProducts.forEach(product => {
      productAsinBySkuMap.set(product.sku, product.asin);
    });

    const asins = skus.map(sku => productAsinBySkuMap.get(sku));

    const dates = getCurrentRangeDates(state.range.type, state.range.dates);

    await TrafficAnalyticsApi.getChart({
      dates,
      asins,
    }).then(res => {
      const data = res.data.trafficResponseDailyDetails;

      commit('setChartSkus', { skus });

      commit('chartSuccess', {
        data: data.sort(function(a, b) {
          return new Date(b.date) - new Date(a.date);
        }),
      });
    });
  },

  async getProducts(
    { commit, state },
    { groupBy } = {
      groupBy: state.products.groupBy,
    }
  ) {
    commit('productsLoading');

    const dates = getCurrentRangeDates(state.range.type, state.range.dates);

    await TrafficAnalyticsApi.getProducts({
      dates,
      groupBy,
    }).then(res => {
      const data = res.data.trafficProductBreakdownList;

      commit('setProductsGroupBy', { groupBy });
      commit('productsSuccess', {
        data,
      });
    });
  },
  setRange({ commit }, { type, dates }) {
    commit('setRange', { type, dates });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
