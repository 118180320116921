import { getPrettyInteger, getPercent } from '@/common/helpers/helpers.js';

class Sales {
  constructor(total = 0, ads = 0, organic = 0) {
    this.total = total;
    this.ads = ads;
    this.organic = organic;
  }

  get total() {
    return this._total;
  }
  get totalPretty() {
    return getPrettyInteger(this.total);
  }

  get ads() {
    return this._ads;
  }
  get adsPretty() {
    return getPrettyInteger(this.ads);
  }

  get organic() {
    return this.total - this.ads;
  }
  get organicPretty() {
    return getPrettyInteger(this.organic);
  }

  get organicPercent() {
    return getPercent(this.organic, this.total);
  }
  get adsPercent() {
    return getPercent(this.ads, this.total);
  }

  set total(val) {
    this._total = val;
  }

  set ads(val) {
    this._ads = val;
  }

  set organic(val) {
    this._organic = val;
  }
}

export { Sales };
