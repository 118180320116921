import { getPrettyInteger } from '@/common/helpers/helpers.js';

class Kpis {
  constructor(
    refundsQuantity = 0,
    unitsQuantityNonZeroSales = 0,
    shipping = 0
  ) {
    this.refundsQuantity = refundsQuantity;
    this.unitsQuantityNonZeroSales = unitsQuantityNonZeroSales;
    this.shipping = shipping;
  }

  get refundsQuantity() {
    return this._refundsQuantity;
  }
  get refundsQuantityPretty() {
    return getPrettyInteger(this.refundsQuantity);
  }

  get unitsQuantityNonZeroSales() {
    return this._unitsQuantityNonZeroSales;
  }
  get shipping() {
    return this._shipping;
  }

  set refundsQuantity(val) {
    this._refundsQuantity = val;
  }
  set unitsQuantityNonZeroSales(val) {
    this._unitsQuantityNonZeroSales = val;
  }
  set shipping(val) {
    this._shipping = val;
  }
}

export { Kpis };
