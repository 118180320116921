const vars = {
  production: {
    mrp_api_endpoint: 'https://api.myrealprofit.com/',
    activate_selling_partner:
      'https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.0aaae7b4-78a2-49dd-b38a-382a750d11db&redirect_uri=https://app.myrealprofit.com/addSellerRedirect&state=amazonSellerAuth',
    renew_selling_partner:
      'https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.0aaae7b4-78a2-49dd-b38a-382a750d11db&redirect_uri=https://app.myrealprofit.com/addSellerRedirect&state=renewToken',
    activate_advertising:
      '?client_id=amzn1.application-oa2-client.4119d96b64e04ad7ad26449174be9654&scope=advertising::campaign_management&response_type=code&redirect_uri=https://app.myrealprofit.com/activateAdvertisingRedirect',
    stripe_publishable_key:
      'pk_live_51Ja3t2DyHb9Y2mVbHMyraokldTljJwMfwO8AjcA0QPySClrkUiHf8a80Ve0QzRldiHjov7MKMsAJitaPwDes8fLe009r1i5gzZ',
  },
  clientGtm: {
    mrp_api_endpoint: 'https://gtm-api.myrealprofit.com/',
    activate_selling_partner:
      'https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.0aaae7b4-78a2-49dd-b38a-382a750d11db&redirect_uri=https://gtm.myrealprofit.com/addSellerRedirect&state=amazonSellerAuth',
    renew_selling_partner:
      'https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.0aaae7b4-78a2-49dd-b38a-382a750d11db&redirect_uri=https://gtm.myrealprofit.com/addSellerRedirect&state=renewToken',
    activate_advertising:
      '?client_id=amzn1.application-oa2-client.4119d96b64e04ad7ad26449174be9654&scope=advertising::campaign_management&response_type=code&redirect_uri=https://gtm.myrealprofit.com/activateAdvertisingRedirect',
    stripe_publishable_key:
      'pk_live_51Ja3t2DyHb9Y2mVbHMyraokldTljJwMfwO8AjcA0QPySClrkUiHf8a80Ve0QzRldiHjov7MKMsAJitaPwDes8fLe009r1i5gzZ',
  },
  beta: {
    mrp_api_endpoint: 'https://api.myrealprofit.com/',
    activate_selling_partner:
      'https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.0aaae7b4-78a2-49dd-b38a-382a750d11db&redirect_uri=https://beta.myrealprofit.com/addSellerRedirect&state=amazonSellerAuth',
    renew_selling_partner:
      'https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.0aaae7b4-78a2-49dd-b38a-382a750d11db&redirect_uri=https://beta.myrealprofit.com/addSellerRedirect&state=renewToken',
    activate_advertising:
      '?client_id=amzn1.application-oa2-client.4119d96b64e04ad7ad26449174be9654&scope=advertising::campaign_management&response_type=code&redirect_uri=https://beta.myrealprofit.com/activateAdvertisingRedirect',
    stripe_publishable_key:
      'pk_live_51Ja3t2DyHb9Y2mVbHMyraokldTljJwMfwO8AjcA0QPySClrkUiHf8a80Ve0QzRldiHjov7MKMsAJitaPwDes8fLe009r1i5gzZ',
    isBeta: true,
  },
  staging: {
    mrp_api_endpoint: 'https://dev.myrealprofit.com/',
    activate_selling_partner:
      'https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.0aaae7b4-78a2-49dd-b38a-382a750d11db&redirect_uri=https://staging.myrealprofit.com/addSellerRedirect&state=amazonSellerAuth',
    renew_selling_partner:
      'https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.0aaae7b4-78a2-49dd-b38a-382a750d11db&redirect_uri=https://staging.myrealprofit.com/addSellerRedirect&state=renewToken',
    activate_advertising:
      '?client_id=amzn1.application-oa2-client.4119d96b64e04ad7ad26449174be9654&scope=advertising::campaign_management&response_type=code&redirect_uri=https://staging.myrealprofit.com/activateAdvertisingRedirect',
    stripe_publishable_key:
      'pk_test_51Ja3t2DyHb9Y2mVb8m9E8okFlEZtGwEVuS5lYuYRlkkDuIvCRTQ0y4gsC5vXNtoppbjcWl0AohfVHxPsPz4ySZVQ00pZFYc5cP',
  },
  localhost: {
    mrp_api_endpoint: 'http://localhost:8080/',
    activate_selling_partner:
      'https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.0aaae7b4-78a2-49dd-b38a-382a750d11db&state=amazonSellerAuth',
    renew_selling_partner:
      'https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.0aaae7b4-78a2-49dd-b38a-382a750d11db&state=renewToken',
    activate_advertising:
      '?client_id=amzn1.application-oa2-client.4119d96b64e04ad7ad26449174be9654&scope=advertising::campaign_management&response_type=code&redirect_uri=https://staging.myrealprofit.com/activateAdvertisingRedirect',
    stripe_publishable_key:
      'pk_test_51Ja3t2DyHb9Y2mVb8m9E8okFlEZtGwEVuS5lYuYRlkkDuIvCRTQ0y4gsC5vXNtoppbjcWl0AohfVHxPsPz4ySZVQ00pZFYc5cP',
  },
};

const env_vars = vars.production;

export { env_vars };
