const state = {
  /* * * * ERROR * * * */
  currentError: null,
  errors: [],
};

const getters = {};

const mutations = {
  /* * * * ERROR * * * */
  ADD_ERROR(state, error) {
    state.errors.push(error);
  },

  SET_CURRENT_ERROR(state, error) {
    state.currentError = error;
  },

  CLEAR_ERRORS(state) {
    state.errors = [];
    state.currentError = null;
  },
};

const actions = {
  /* * * * ERROR * * * */
  add_error({ commit }, error) {
    commit('SET_CURRENT_ERROR', error);
    commit('ADD_ERROR', error);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
