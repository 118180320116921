var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item.path)?_c('router-link',{attrs:{"to":_vm.item.path},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"justify-start px-3 app-sidebar-link",attrs:{"to":_vm.item.path,"elevation":"0","color":_vm.buttonColor(isActive),"text":!(isActive || _vm.includedPathActive() || _vm.includedNameActive()),"block":""}},[_c('v-icon',{staticClass:"mr-3 sidebar-icon-width",class:_vm.iconClass(isActive, hover)},[_vm._v(" "+_vm._s(_vm.item.iconName)+" ")]),_vm._v(" "+_vm._s(_vm.item.text)+" "),(_vm.item.chip)?_c('v-chip',{staticClass:"ma-2 rounded px-2 py-0",attrs:{"small":"","color":_vm.item.chip.backgroundColor,"text-color":_vm.item.chip.textColor}},[_vm._v(" "+_vm._s(_vm.item.chip.text)+" ")]):_vm._e(),_vm._t("after")],2)]}}],null,true)})]}}],null,false,702907465)}):_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"justify-start px-3 app-sidebar-link",attrs:{"block":"","elevation":"0","text":"","color":_vm.buttonColor(false)},on:{"click":_vm.item.callback}},[_c('v-icon',{staticClass:"mr-3 sidebar-icon-width",class:_vm.iconClass(false, hover)},[_vm._v(" "+_vm._s(_vm.item.iconName)+" ")]),_vm._v(" "+_vm._s(_vm.item.text)+" "),_vm._t("after")],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }