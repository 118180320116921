import Vue from 'vue';
import App from '@/App.vue';
import VueMeta from 'vue-meta';

import VueCompositionApi from '@vue/composition-api';

require('./plugins');
require('./interceptors');

import router from '@/router/index';
import store from '@/store';
import vuetify from './plugins/vuetify';
import Api from '@/api/api';

Vue.config.productionTip = false;

// TODO in future: Use vuetify variables
Vue.prototype.$mobile = window.innerWidth <= 700;

export const $api = new Api();

Vue.use(VueCompositionApi);
Vue.use(VueMeta);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
