import { getPrettyInteger, getPercent } from '@/common/helpers/helpers.js';

class Units {
  constructor(total = 0, coupons = 0, fullPrice = 0) {
    this.total = total;
    this.coupons = coupons;
    this.fullPrice = fullPrice;
  }

  get total() {
    return this._total;
  }
  get totalPretty() {
    return getPrettyInteger(this.total);
  }

  get coupons() {
    return this._coupons;
  }
  get couponsPretty() {
    return getPrettyInteger(this.coupons);
  }

  get fullPrice() {
    return this.total - this.coupons;
  }
  get fullPricePretty() {
    return getPrettyInteger(this.fullPrice);
  }

  get couponsPercent() {
    return getPercent(this.coupons, this.total);
  }
  get fullPricePercent() {
    return getPercent(this.fullPrice, this.total);
  }

  set total(val) {
    this._total = val;
  }

  set coupons(val) {
    this._coupons = val;
  }

  set fullPrice(val) {
    this._fullPrice = val;
  }
}

export { Units };
