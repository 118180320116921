import store from '@/store';
import { CANCELLED_MESSAGE } from '@/api/api';
import { axiosInstance } from '@/plugins';

const not_auth_endpoints = ['signup', 'oauth/token'];

axiosInstance.interceptors.response.use(
  function(response) {
    return response;
  },
  async function(error) {
    if (error.message === CANCELLED_MESSAGE) {
      return Promise.reject(error);
    }

    if (error.response?.status === 401) {
      await store.dispatch('logout');

      await store.dispatch('change_loading_state', false);
    }

    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(function(config) {
  if (not_auth_endpoints.includes(config.url)) {
    return config;
  }

  const token = store.getters.accessToken;

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
});
