import { getPrettyInteger } from '@/common/helpers/helpers.js';

class Subexpense {
  constructor(name, total = 0) {
    this.name = name;
    this.total = total;
  }

  /* * * * * * * GETTERS * * * * * * * * */
  get name() {
    return this._name;
  }
  get total() {
    return this._total;
  }
  get totalPretty() {
    return getPrettyInteger(this.total);
  }
  /* * * * * * * * * * * * * * * * * * * */

  /* * * * * * * SETTERS * * * * * * * * */
  set name(val) {
    this._name = val;
  }
  set total(val) {
    this._total = val;
  }
  /* * * * * * * * * * * * * * * * * * * */

  addAssign(subexpenseToAdd) {
    this.total += subexpenseToAdd.total;
  }
}

export { Subexpense };
