import axios from 'axios';
import router from '@/router';

import * as fetcher from '@/api/fetcher';
import qs from 'qs';
import { $api } from '@/main';
import { mrpDataLayer } from '@/analytics/gtm';

const state = {
  /* * * * USER * * * */
  user: null,
  userStatus: 'unauthorized', // unauthorised, fetching, authorised
  userEmail: null,
  userId: null,
  referral: null,

  /* * * * TOKEN * * * */
  token: {
    access_token: null,
    refresh_token: null,
  },
};

const getters = {
  /* * * * USER * * * */
  userStatus: state => state.userStatus,
  userEmail: state => state.userEmail,

  /* * * * TOKEN * * * */
  token: state => state.token,
  accessToken: state => state.token.access_token,
  refreshToken: state => state.token.refresh_token,

  userId: state => state.userId,

  referral: state => state.referral,
};

const mutations = {
  /* * * * USER * * * */
  SET_USER_STATUS(state, status) {
    state.userStatus = status;
  },
  SET_USER_EMAIL(state, email) {
    state.userEmail = email;
  },

  SET_USER_ID(state, userId) {
    state.userId = userId;
  },

  /* * * * TOKEN * * * */
  SET_TOKEN(state, token) {
    state.token = token;
  },

  SET_REFERRAL(state, referral) {
    state.referral = referral;
  },

  CLEAR_TOKEN(state) {
    state.token = {
      access_token: null,
      refresh_token: null,
    };
    axios.defaults.headers.common['Authorization'] = null;
  },
};

const actions = {
  /* * * * USER * * * */
  register_controller({ dispatch }, credentials) {
    dispatch('change_loading_state', true);
    const data = {
      clientSecret: { clientId: 'client', clientSecret: 'secret' },
      myRealProfitUser: { ...credentials },
      influencerCoupon: credentials.influencerCoupon,
    };
    const config = {
      headers: { 'Content-Type': 'application/json' },
    };
    $api
      .post('signup', data, config)
      .then(async response => {
        const { userID } = response.data;
        mrpDataLayer.anonymous.push({
          event: 'User registered successfully',
          userID,
        });

        const login_credentials = {
          username: credentials.username,
          password: credentials.password,
        };
        dispatch('login_controller', login_credentials);
      })
      .catch(() => {
        dispatch('change_loading_state', false);
        dispatch('logAlertError', {
          msg:
            'We were unable to create a new user with these credentials. Please try again.',
        });
      });
  },

  async login_controller({ dispatch }, credentials) {
    await dispatch('clear_session');
    dispatch('change_loading_state', true);
    dispatch('login', credentials)
      .then(async () => {
        dispatch('change_loading_state', false);
        router.push({ name: 'dashboard' });
      })
      .catch(error => {
        dispatch('change_loading_state', false);

        const status = error?.response?.status || 500;

        if (status === 400 || status === 401) {
          dispatch('logAlertError', {
            msg: 'Invalid email or password. Please try again.',
          });
        } else {
          dispatch('logAlertError', {
            msg:
              'Unknown error occurred during user authentication.<br />Please contact with support in the bottom right corner.',
          });
        }
      });
  },

  login({ commit }, credentials) {
    const data = qs.stringify({ grant_type: 'password', ...credentials });

    const config = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      auth: { username: 'client', password: 'secret' },
    };

    return $api.post('oauth/token', data, config).then(async ({ data }) => {
      commit('SET_USER_STATUS', 'authorized');
      commit('SET_USER_EMAIL', credentials.username);
      commit('SET_TOKEN', data);

      const userData = await fetcher.fetch_user_details();
      commit('SET_USER_ID', userData.id);

      mrpDataLayer.authenticated.push({
        event: 'User logged in successfully',
      });

      mrpDataLayer.authenticated.push({
        event: 'start_pw',
        pw_user_email: credentials.username,
      });

      window.pendo.initialize({
        visitor: {
          id: userData.id,
        },
        account: {
          id: userData.id,
        },
      });
    });
  },

  refresh_token({ getters, commit, dispatch }) {
    const post_data = qs.stringify({
      grant_type: 'refresh_token',
      refresh_token: getters.refreshToken,
    });
    const config = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      auth: { username: 'client', password: 'secret' },
    };
    return $api
      .post('oauth/token', post_data, config)
      .then(async ({ data }) => {
        commit('SET_TOKEN', data);
      })
      .catch(() => {
        dispatch('logout');
        dispatch('change_loading_state', false);
        return;
      });
  },

  logout({ dispatch }) {
    router.push({ name: 'login' });
    dispatch('clear_session');
  },

  clear_user({ commit }) {
    commit('SET_USER_STATUS', 'unauthorized');
    commit('SET_USER_EMAIL', null);
    commit('SET_USER_ID', null);
    commit('SET_REFERRAL', null);
    commit('CLEAR_TOKEN');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
