<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.9999 0.800049C8.33127 0.800049 8.5999 1.06868 8.5999 1.40005V2.60005C8.5999 2.93142 8.33127 3.20005 7.9999 3.20005C7.66853 3.20005 7.3999 2.93142 7.3999 2.60005V1.40005C7.3999 1.06868 7.66853 0.800049 7.9999 0.800049ZM4.0401 2.44025C4.27442 2.20594 4.65432 2.20594 4.88863 2.44025L5.73716 3.28878C5.97148 3.52309 5.97148 3.90299 5.73716 4.13731C5.50285 4.37162 5.12295 4.37162 4.88863 4.13731L4.0401 3.28878C3.80579 3.05446 3.80579 2.67457 4.0401 2.44025ZM11.9597 2.44025C12.194 2.67457 12.194 3.05446 11.9597 3.28878L11.1112 4.13731C10.8769 4.37162 10.497 4.37162 10.2626 4.13731C10.0283 3.90299 10.0283 3.52309 10.2626 3.28878L11.1112 2.44025C11.3455 2.20594 11.7254 2.20594 11.9597 2.44025ZM2.3999 6.40005C2.3999 6.06868 2.66853 5.80005 2.9999 5.80005H4.1999C4.53127 5.80005 4.7999 6.06868 4.7999 6.40005C4.7999 6.73142 4.53127 7.00005 4.1999 7.00005H2.9999C2.66853 7.00005 2.3999 6.73142 2.3999 6.40005ZM11.1999 6.40005C11.1999 6.06868 11.4685 5.80005 11.7999 5.80005H12.9999C13.3313 5.80005 13.5999 6.06868 13.5999 6.40005C13.5999 6.73142 13.3313 7.00005 12.9999 7.00005H11.7999C11.4685 7.00005 11.1999 6.73142 11.1999 6.40005ZM5.73716 8.66279C5.97148 8.8971 5.97148 9.277 5.73716 9.51132L4.88863 10.3598C4.65432 10.5942 4.27442 10.5942 4.0401 10.3598C3.80579 10.1255 3.80579 9.74563 4.0401 9.51132L4.88863 8.66279C5.12295 8.42848 5.50285 8.42848 5.73716 8.66279ZM8.61306 6.00849C8.47023 5.79593 8.20822 5.69746 7.96075 5.76333C7.71327 5.8292 7.53487 6.04488 7.51659 6.30032L7.12378 11.789C7.10693 12.0245 7.22971 12.2479 7.43749 12.36C7.64527 12.472 7.89943 12.4518 8.08689 12.3083L8.92118 11.6699L9.70959 14.6122C9.79535 14.9323 10.1244 15.1222 10.4444 15.0365C10.7645 14.9507 10.9545 14.6217 10.8687 14.3016L10.0872 11.3849L11.1209 11.548C11.3566 11.5851 11.5919 11.4791 11.7202 11.2779C11.8486 11.0768 11.8455 10.8187 11.7124 10.6206L8.61306 6.00849Z"
      class="app-fill-current"
    />
  </svg>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'icon-traffic-analytics',
});
</script>
