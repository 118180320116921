const state = {
  /* * * * MARKETPLACE * * * */
  currentMarketplaceStatus: 'active', // empty, active
  currentMarketplace: {
    amazonMarketplaceId: 'ATVPDKIKX0DER',
    currencyCode: 'USD',
    regionName: 'North America',
  },

  marketplacesStatus: 'fetched', // empty, fetching, fetched
  marketplaces: [
    {
      amazonMarketplaceId: 'ATVPDKIKX0DER',
      currencyCode: 'USD',
      regionName: 'North America',
    },
  ],
};

const getters = {
  /* * * * MARKETPLACE * * * */
  currentMarketplaceStatus: state => state.currentMarketplaceStatus,
  currentMarketplaceId: state => state.currentMarketplace.amazonMarketplaceId,
  currentCurrencyCode: state => state.currentMarketplace.currencyCode,
  currentRegionName: state => state.currentMarketplace.regionName,

  marketplacesStatus: state => state.marketplacesStatus,
  marketplaces: state => state.marketplaces,
};

const mutations = {
  /* * * * MARKETPLACE * * * */
  SET_CURRENT_MARKETPLACE(state, marketplace) {
    state.currentMarketplace = marketplace;
  },

  SET_CURRENT_MARKETPLACE_STATUS(state, status) {
    state.currentMarketplaceStatus = status;
  },

  ADD_MARKETPLACE(state, marketplace) {
    state.marketplace.push(marketplace);
  },

  SET_MARKETPLACES_STATUS(state, status) {
    state.marketplacesStatus = status;
  },

  CLEAR_MARKETPLACES(state) {
    state.currentMarketplaceStatus = 'empty';
    state.currentMarketplace = { amazonMarketplaceId: null };

    state.marketplacesStatus = 'empty';
    state.marketplaces = [];
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
