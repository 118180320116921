<template>
  <div class="sidenav">
    <a href="https://myrealprofit.com">
      <img
        src="@/assets/svg/logo.svg"
        alt="MRP logo"
        class="sidenav__logo mb-lg-13"
      />
    </a>
    <v-btn icon class="ml-auto d-lg-none" @click="toggleSidebarItems()">
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <div
      class="sidenav__items"
      :class="{ 'sidenav__items--active': sidebarItems }"
    >
      <h3 class="sidenav__title">Demo Reports</h3>
      <ul class="nav mb-13">
        <li v-for="item in demoReports" :key="item.text">
          <sidebar-link :item="item"></sidebar-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SidebarLink from '@/common/components/SidebarLink';

export default {
  name: 'SidebarNav',
  components: {
    SidebarLink,
  },
  data() {
    return {
      sidebarItems: false,
    };
  },
  created() {},
  watch: {
    $route() {
      document.documentElement.classList.remove('disable-scroll');
      this.sidebarItems = false;
    },
  },
  methods: {
    toggleSidebarItems() {
      if (this.sidebarItems) {
        document.documentElement.classList.remove('disable-scroll');
      } else {
        document.documentElement.classList.add('disable-scroll');
      }
      this.sidebarItems = !this.sidebarItems;
    },
  },
  computed: {
    demoReports() {
      return [
        {
          text: 'Profit Analysis',
          iconName: '$vuetify.icons.profitAnalysis',
          path: '/profitAndLossDemo',
        },
        {
          text: 'Advertising Analytics',
          iconName: '$vuetify.icons.advertisingInsights',
          path: '/advertisingDemo',
        },
        {
          text: 'Market Share & Funnel',
          iconName: '$vuetify.icons.marketShare',
          path: '/marketShareDemo',
        },
        {
          text: 'Day to Day',
          iconName: '$vuetify.icons.dayToDay',
          path: '/dayToDayDemo',
        },
        {
          text: 'Refunds Analytics',
          iconName: '$vuetify.icons.dashboard',
          path: '/refundsAnalyticsDemo',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .sidenav__items {
    position: absolute;
    top: 100%;
    width: 100%;
    padding: 16px 32px;
    background: white;
    height: calc(100vh - var(--sidebar-mobile-height));
    overflow: auto;
    left: 0;
    display: none;
    &--active {
      display: block;
    }
  }
}

.sidenav {
  background: white;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 10;
  padding: 20px 16px 20px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    position: fixed;
    width: var(--sidebar-width);
    height: 100vh;
    border-right: 1px solid #e7eaf3;
    padding: 40px 30px;
    display: block;
    box-shadow: none;
    left: 0;
  }

  &__logo {
    width: 86px;
    height: 34px;
    display: block;
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      width: 118px;
      height: 46px;
      margin: 0 auto;
    }
  }

  &__title {
    font: 13px/1em Rajdhani-Medium;
    text-transform: uppercase;
    text-align: unset;
    color: var(--main-gray-color);
    margin: 0 0 12px 0;
  }

  &__text {
    font-weight: bold;
    color: var(--main-gray-color);
  }
}

.nav {
  margin: 0;
  padding: 0;
  text-align: left;
  list-style: none;
  margin: 0 -15px;
}
</style>
