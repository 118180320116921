var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alerts-container"},[(_vm.alerts.length > 0)?_c('div',{staticClass:"alerts-list position-fixed flex-column align-center"},_vm._l((_vm.alerts),function(alert){return _c('div',{key:alert.id,staticClass:"alerts-list-alert flex-row align-center justify-space-between",class:{
        'alerts-list-alert__error': alert.type === _vm.alertType.ERROR,
        'alerts-list-alert__success': alert.type === _vm.alertType.SUCCESS,
        'alerts-list-alert__info': alert.type === _vm.alertType.INFO,
      },on:{"mouseenter":function($event){return _vm.pauseAlert(alert)},"mouseleave":function($event){return _vm.unpauseAlert(alert)}}},[_c('div',{staticClass:"flex-row"},[_c('img',{attrs:{"src":_vm.alertPrefix(alert.type).src,"alt":_vm.alertPrefix(alert.type).alt}}),_c('horizontal-space-divider',{attrs:{"config":{ width: '12px' }}}),_c('inter',{attrs:{"content":{
            text: alert.msg,
          },"config":{
            weight: 'Medium',
            color: 'var(--gray-500)',
            'font-size': '14px',
            'line-height': '22px',
          }}})],1),_c('img',{staticClass:"alerts-list-alert-suffix",attrs:{"src":_vm.alertSuffix(alert.type).src,"alt":"Remove Icon"},on:{"click":function($event){return _vm.removeAlert(alert)}}})])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }