import store from '@/store';

export const initialDataLoadedGuard = (to, from, next) => {
  const sellerDataLoaded =
    store.getters.currentSellerDetails.sellerDataInitialized;
  if (!sellerDataLoaded) {
    next('/sellerDataInProgress');
  } else {
    next();
  }
};
