import store from '@/store';

const days_available_with_overdue_subscription = 5;

export const requiredSubscriptionGuard = (to, from, next) => {
  if (shouldNotifyOverdue()) {
    notifyOverdue();
    next();
  } else if (!hasActiveSubscription()) {
    next('/add-card');
  } else {
    next();
  }
};

function shouldNotifyOverdue() {
  if (!store.getters.subscriptionOverdue) {
    return;
  }

  let start = new Date(
    Date.parse(store.getters.subscriptionDetails.currentPeriodStart)
  );
  start.setDate(start.getDate() + days_available_with_overdue_subscription);
  const now = new Date(Date.now());
  return now < start && store.getters.subscriptionDetails.cardLastDigits;
}

function notifyOverdue() {
  store.dispatch('logAlertError', {
    msg:
      'We were unable to process your payment. Please <a href="/add-card">update your payment method</a>',
    delay: 10000,
  });
}

function hasActiveSubscription() {
  return store.getters.subscriptionActive;
}
