import store from '@/store';

export const subscriptionDataResolver = async (to, from, next) => {
  await store.dispatch('change_loading_state', true);

  const subscriptionLoaded = store.getters.subscriptionLoaded;

  try {
    if (!subscriptionLoaded) {
      await store.dispatch('get_subscription_controller');
    }
  } catch (error) {
    if (error.isAxiosError && error.response?.status === 401) {
      return;
    }

    await store.dispatch('logAlertError', {
      msg:
        'Unknown error occurred during retrieving subscription information.<br /> Please contact with support in the bottom right corner.',
      delay: 100 * 1000, // 100 seconds
    });

    await store.dispatch('clear_session');
    await store.dispatch('change_loading_state', false);
    next('/login');
    return;
  }

  const sellersLoaded = store.getters.sellersLoaded;

  try {
    if (!sellersLoaded) {
      await store.dispatch('fetch_sellers_controller');
    }
  } catch (error) {
    if (error.isAxiosError && error.response?.status === 401) {
      return;
    }

    await store.dispatch('logAlertError', {
      msg:
        'Unknown error occurred during retrieving seller information.<br /> Please contact with support in the bottom right corner.',
      delay: 100 * 1000, // 100 seconds
    });

    await store.dispatch('clear_session');
    await store.dispatch('change_loading_state', false);
    next('/login');
    return;
  }

  await store.dispatch('change_loading_state', false);

  next();
};
