<template>
  <span
    class="content"
    :class="{ gradient: gradient }"
    :style="computedStyles"
    v-html="content.text"
  ></span>
</template>

<script>
export default {
  name: 'Inter',
  props: ['content', 'config'],
  data() {
    return {
      allowed_weights: [
        'Light',
        'Regular',
        'Medium',
        'SemiBold',
        'Bold',
        'ExtraBold',
        'Black',
      ],

      // default styles
      'font-family': 'Inter-Regular',
      'font-size': '1em',
      'line-height': '1em',
      'text-transform': 'none',
      'letter-spacing': '0em',
      'text-align': 'unset',
      'text-decoration': 'unset',
      'text-decoration-color': 'unset',
      background: 'unset',
      color: 'var(--main-dark-color)',
      gradient: false,
    };
  },
  created() {
    this.setConfigData();
  },
  methods: {
    setConfigData() {
      if (this.config) {
        if (
          this.config.weight &&
          this.allowed_weights.includes(this.config.weight)
        ) {
          this['font-family'] = 'Inter-' + this.config.weight;
        }
        this['font-size'] = this.config['font-size']
          ? this.config['font-size']
          : this['font-size'];
        this['line-height'] = this.config['line-height']
          ? this.config['line-height']
          : this['font-size'];
        this['text-transform'] = this.config['text-transform']
          ? this.config['text-transform']
          : this['text-transform'];
        this['letter-spacing'] = this.config['letter-spacing']
          ? this.config['letter-spacing']
          : this['letter-spacing'];
        this['text-align'] = this.config['text-align']
          ? this.config['text-align']
          : this['text-align'];
        this['text-decoration'] = this.config['text-decoration']
          ? this.config['text-decoration']
          : this['text-decoration'];
        this['text-decoration-color'] = this.config['text-decoration-color']
          ? this.config['text-decoration-color']
          : this['text-decoration-color'];

        this.color = this.config.color ? this.config.color : this.color;
        this.background = this.config.background
          ? this.config.background
          : this.background;

        this.gradient = this.config.gradient
          ? this.config.gradient
          : this.gradient;
      }
    },
  },
  computed: {
    computedStyles: function() {
      return [
        {
          'font-family': this['font-family'],
          'font-size': this['font-size'],
          'line-height': this['line-height'],
          'text-transform': this['text-transform'],
          'letter-spacing': this['letter-spacing'],
          'text-align': this['text-align'],
          'text-decoration': this['text-decoration'],
          'text-decoration-color': this['text-decoration-color'],
          color: this.color,
          background: this.background,
        },
      ];
    },
  },
};
</script>

<style scoped>
.content {
  font-style: normal;
  white-space: pre-line;
}
.gradient {
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -moz-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  -moz-text-fill-color: transparent !important;
}
</style>
