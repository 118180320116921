<template>
  <v-app>
    <alert></alert>
    <loader-overlay></loader-overlay>
    <router-view></router-view>
  </v-app>
</template>

<script>
/* eslint-disable */
import Alert from '@/common/components/Alert.vue';
import LoaderOverlay from '@/common/components/LoaderOverlay';
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';

export default {
  name: 'App',
  components: {
    LoaderOverlay,
    Alert,
  },
  computed: {
    ...mapGetters({
      accessToken: 'accessToken',
    }),
  },
  methods: {
    showMaintenanceAlert() {
      const alertKey = 'MRP_MAINTENANCE_ALERT_03_08_1';
      const alreadySeen = localStorage.getItem(alertKey);

      if (alreadySeen) {
        return;
      }

      const startDate = DateTime.fromObject({
        day: 3,
        month: 8,
        year: 2024,
        hour: 10,
        minute: 0,
        zone: 'Europe/Warsaw',
      });

      const endDate = DateTime.fromObject({
        day: 3,
        month: 8,
        year: 2024,
        hour: 13,
        minute: 0,
        zone: 'Europe/Warsaw',
      });

      const currentDate = DateTime.now();

      if (currentDate.valueOf() > endDate.valueOf()) {
        localStorage.setItem(alertKey, 'true');
        return;
      }

      const dateRange = getDateRange(startDate, endDate);

      this.$store.dispatch('logAlertInfo', {
        msg: `${dateRange}, our service will be unavailable due to maintenance work. We apologize for any inconvenience and appreciate your patience.`,
        delay: 1000 * 60 * 10,
      });
      localStorage.setItem(alertKey, 'true');
    },
  },
  watch: {
    accessToken: {
      immediate: true,
      handler(value) {
        // if (!value) {
        //   return;
        // }
        // this.showMaintenanceAlert();
      },
    },
  },
};

function getDateRange(startDate, endDate) {
  const local = DateTime.local().setLocale('en-US');

  const startDateLocal = startDate.setZone(local.zoneName);
  const endDateLocal = endDate.setZone(local.zoneName);

  const newDay = startDateLocal.get('day') !== endDateLocal.get('day');

  const startDay = startDateLocal.toFormat('LLL d');
  const endDay = endDateLocal.toFormat('LLL d');
  const startHour = startDateLocal.toLocaleString(DateTime.TIME_SIMPLE);
  const endHour = endDateLocal.toLocaleString(DateTime.TIME_SIMPLE);
  const zoneCode = local.offsetNameShort;

  if (newDay) {
    return `On the night of ${startDay} to ${endDay}, from <strong>${startHour} to ${endHour} <small>${zoneCode}</small></strong>`;
  }

  return `On ${startDay}th, from <strong>${startHour} to ${endHour} <small>${zoneCode}</small></strong>`;
}
</script>

<style lang="scss">
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-material.css';
@import 'scss/vuetify/vuetify';
@import 'scss/ag-grid/theme';
@import 'assets/css/customFonts.css';
@import 'assets/css/common.css';
@import 'assets/css/placeholder.scss';
@import 'assets/css/typography.scss';

:root {
  --sidebar-mobile-height: 82px;
  --application-width: calc(100% - var(--sidebar-width));
  --sidebar-width: 256px;
  --content-box-width: 1080px;
  --universal-padding: 13.5px;
  --card-width: 313px;

  --small-card-width: 350px;
  --big-card-width: 730px;
  --small-card-height: 115px;
  --big-card-height: 495px;

  --main-background-color: #f5f6fa;

  --white: #ffffff;
  --black: #000000;

  --gray-900: #111827;
  --gray-700: #374151;
  --gray-600: #4b5563;
  --gray-500: #6b7280;
  --gray-400: #9ca3af;
  --gray-200: #b6b7c2;
  --gray-100: #e5e7eb;
  --gray-50: #f9fafb;

  --purple-900: #5952e5;
  --purple-700: #635bff;
  --purple-500: #776ae5;

  --green-900: #00e5e2;
  --green-800: #065f46;
  --green-500: #10b981;
  --green-400: #34d399;
  --green-50: #ecfdf5;

  --red-800: #991b1b;
  --red-500: #ef4444;
  --red-400: #f87171;
  --red-50: #fef2f2;

  --main-gray-color: #6b7280;
  --secondary-gray-color: #374151;
  --third-gray-color: #9ca3af;
  --fourth-gray-color: #1f2937;
  --fifth-gray-color: #b6b7c2;

  --main-purple-color: #776ae6;
  --secondary-purple-color: #8073e5;
  --third-purple-color: #e5e8ff;
  --fourth-purple-color: #8079bd;

  --secondary-button-purple-color: #f8f7ff;

  --main-green-color: #4fa186;
  --secondary-green-color: #73bfb6;

  --main-red-color: #c47c7c;
}

html {
  overflow-y: auto;
}

a {
  text-decoration: none;
}

* {
  overflow-anchor: none;
  box-sizing: border-box;
}

body {
  margin: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

html {
  scroll-behavior: smooth;
}

.disable-scroll {
  overflow: hidden;
}

.app-fill-current {
  fill: currentColor;
}

.page-container {
  display: flex;
  width: 100%;
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    padding-top: var(--sidebar-mobile-height);
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    --page-margin: 15px;
    padding-left: calc(var(--sidebar-width) + var(--page-margin));
    padding-right: var(--page-margin);
  }
}
</style>
