import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { customIcons } from '@/common/icons/icons';
import colors from 'vuetify/es5/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#776ae6',
        secondary: '#111827',
        accent: '#e8e6f5',
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3,
        green: '#4fa186',
        'secondary-green': '#73bfb6',
        'secondary-purple': '#8073e5',
        red: '#c47c7c',
        'secondary-red': '#f9d2d2',
      },
    },
  },
  icons: {
    values: customIcons,
  },
});
