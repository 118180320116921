import store from '@/store';

export const mrpDataLayer = {
  authenticated: {
    push: data => {
      const userID = store.getters.userId;

      window.dataLayer = window.dataLayer || [];

      window.dataLayer.push({
        ...data,
        userID,
      });
    },
  },
  anonymous: {
    push: data => {
      window.dataLayer = window.dataLayer || [];

      window.dataLayer.push({ ...data });
    },
  },
};
