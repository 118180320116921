<template>
  <div class="page-container">
    <sidebar-nav></sidebar-nav>
    <v-container class="py-13">
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <router-view></router-view>
      </fade-transition>
    </v-container>
  </div>
</template>

<script>
import SidebarNav from '@/modules/CustomReportsDemoLayout/SidebarNav/SidebarNav.vue';
import { FadeTransition } from 'vue2-transitions';

export default {
  name: 'CustomReportsDemoLayout',
  data() {
    return {
      initialized: false,
    };
  },
  components: {
    SidebarNav,
    FadeTransition,
  },
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';
/* TODO: 425 */
.theme--light.v-application {
  background-color: var(--main-background-color) !important;
}
</style>
