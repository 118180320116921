const state = {
  loading: false,
};

const getters = {
  loading: state => state.loading,
};

const mutations = {
  SET_LOADING_STATE(state, status) {
    state.loading = status;
  },
};

const actions = {
  change_loading_state({ commit }, status) {
    commit('SET_LOADING_STATE', status);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
