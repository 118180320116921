import store from '@/store';

export const requiredSellersGuard = (to, from, next) => {
  const hasSellers = store.getters.sellers.length;

  if (!hasSellers) {
    next('/settings');
  } else {
    next();
  }
};
