<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.75 1.00006C3.05964 1.00006 2.5 1.5597 2.5 2.25006V13.7501C2.5 14.4404 3.05964 15.0001 3.75 15.0001H12.25C12.9404 15.0001 13.5 14.4404 13.5 13.7501V8.50006C13.5 7.11935 12.3807 6.00006 11 6.00006H9.75C9.05964 6.00006 8.5 5.44042 8.5 4.75006V3.50006C8.5 2.11935 7.38071 1.00006 6 1.00006H3.75ZM5 10.0001C5 9.72392 5.22386 9.50006 5.5 9.50006H10.5C10.7761 9.50006 11 9.72392 11 10.0001C11 10.2762 10.7761 10.5001 10.5 10.5001H5.5C5.22386 10.5001 5 10.2762 5 10.0001ZM5.5 11.5001C5.22386 11.5001 5 11.7239 5 12.0001C5 12.2762 5.22386 12.5001 5.5 12.5001H8C8.27614 12.5001 8.5 12.2762 8.5 12.0001C8.5 11.7239 8.27614 11.5001 8 11.5001H5.5Z"
      class="app-fill-current"
    />
    <path
      d="M8.64746 1.21066C9.17864 1.82438 9.5 2.62469 9.5 3.50006V4.75006C9.5 4.88813 9.61193 5.00006 9.75 5.00006H11C11.8754 5.00006 12.6757 5.32142 13.2894 5.8526C12.6975 3.58665 10.9134 1.80258 8.64746 1.21066Z"
      class="app-fill-current"
    />
  </svg>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'icon-day-to-day',
});
</script>
