<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.00029 2.40002C1.33755 2.40002 0.800293 2.93728 0.800293 3.60002V6.80002C0.800293 7.46277 1.33755 8.00002 2.00029 8.00002H6.80029C7.46303 8.00002 8.00029 7.46277 8.00029 6.80002V3.60002C8.00029 2.93728 7.46303 2.40002 6.80029 2.40002H2.00029ZM10.8002 4C10.1375 4 9.60021 4.53726 9.60021 5.2V10.8C9.60021 11.4627 10.1375 12 10.8002 12H14.0002C14.663 12 15.2002 11.4627 15.2002 10.8V5.2C15.2002 4.53726 14.663 4 14.0002 4H10.8002ZM2.80041 9.60005C2.13767 9.60005 1.60041 10.1373 1.60041 10.8001V12.4001C1.60041 13.0628 2.13767 13.6001 2.80041 13.6001H7.60041C8.26316 13.6001 8.80042 13.0628 8.80042 12.4001V10.8001C8.80042 10.1373 8.26316 9.60005 7.60041 9.60005H2.80041Z"
      class="app-fill-current"
    />
  </svg>
</template>

<script>
//fill="#9CA3AF"
import Vue from 'vue';
export default Vue.extend({
  name: 'icon-profit-dashboard',
});
</script>
