import { Products } from '@/store/classes/products';
import { Product } from '@/store/classes/product';

class ProductVariation {
  constructor() {
    this.variationTotal = new Product();
    this.subproducts = new Products();
  }

  /* * * * * * * GETTERS * * * * * * * * */
  get variationTotal() {
    return this._variationTotal;
  }

  get subproducts() {
    return this._subproducts;
  }
  /* * * * * * * * * * * * * * * * * * * */

  /* * * * * * * SETTERS * * * * * * * * */
  set variationTotal(val) {
    this._variationTotal = val;
  }

  set subproducts(val) {
    this._subproducts = val;
  }
  /* * * * * * * * * * * * * * * * * * * */

  injectBestseller(backendProductVariation, category) {
    let variationTotal = new Product(
      category + ': ' + backendProductVariation.variation,
      category + ': ' + backendProductVariation.variation,
      backendProductVariation.bestsellerName,
      category + ': ' + backendProductVariation.variation,
      backendProductVariation.bestsellerImg,
      backendProductVariation.averagePrice
    );
    variationTotal.injectSales(backendProductVariation.sales);
    variationTotal.injectUnits(backendProductVariation.units);
    variationTotal.injectExpenses(backendProductVariation.expenses);
    variationTotal.injectKpis(backendProductVariation.kpis);
    this.variationTotal = variationTotal;
  }

  injectSubproduct(backendSubproduct) {
    this.subproducts.injectProduct(backendSubproduct);
  }
}

export { ProductVariation };
