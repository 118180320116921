import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';

import auth from '@/store/modules/auth';

import marketplaces from '@/store/modules/marketplaces';
import dateranges from '@/store/modules/dateranges';
import sellers from '@/store/modules/sellers';
import customReports from '@/store/modules/customReports';
import dashboard from '@/store/modules/dashboard';
import alert from '@/store/modules/alert';
import subscriptions from '@/store/modules/subscriptions';
import loader from '@/store/modules/loader';

import errors from '@/store/modules/errors';

import salesAndProfit from '@/store/modules/salesAndProfit';
import products from '@/store/modules/products';

import trafficAnalytics from '@/store/modules/trafficAnalytics';

import { vuexLocal, vuexCookie } from '@/store/vuex-persist';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    subscriptions,
    sellers,
    customReports,
    marketplaces,
    dateranges,
    dashboard,
    errors,
    alert,
    loader,
    salesAndProfit,
    products,
    trafficAnalytics,
  },
  plugins: [vuexLocal.plugin, vuexCookie.plugin],

  state: {},

  mutations: {},

  actions: {
    go_to_maintenance() {
      router.push({ name: 'maintenance' });
    },

    clear_session({ dispatch, commit }) {
      dispatch('clear_sellers_and_status');
      //commit("CLEAR_MARKETPLACES");

      dispatch('salesAndProfit/reset');

      dispatch('clear_dashboard_and_status');

      commit('CLEAR_ERRORS');

      dispatch('clear_subscriptions');
      dispatch('clear_user');

      dispatch('customReports/clear_reports');
    },
  },

  getters: {},
});
