import DashboardLayout from '@/modules/DashboardLayout/DashboardLayout.vue';
import AuthLayout from '@/modules/AuthLayout/AuthLayout.vue';
import CustomReportsDemoLayout from '@/modules/CustomReportsDemoLayout/CustomReportsDemoLayout.vue';

import { requiredSubscriptionGuard } from '@/router/guards/requiredSubscriptionGuard';
import { requiredSellersGuard } from '@/router/guards/requiredSellersGuard';
import { initialDataLoadedGuard } from '@/router/guards/initialDataLoadedGuard';
import { multipleGuards } from '@/router/guards/multiple-guards';
import { subscriptionDataResolver } from '@/router/guards/subscriptionDataResolver';

const routes = [
  {
    path: '/',
    redirect: 'login',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/AuthLayout/Login/Login.vue'
          ),
      },
      {
        path: '/user/create-new-password',
        name: 'create-new-password',
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/AuthLayout/ResetPassword/ResetPassword.vue'
          ),
      },
      {
        path: '/register',
        name: 'register',
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/AuthLayout/Register/Register.vue'
          ),
      },
      {
        path: '/maintenance',
        name: 'maintenance',
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/AuthLayout/Maintenance/Maintenance.vue'
          ),
      },
      {
        path: '/trivium-register',
        name: 'triviumRegister',
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/AuthLayout/TriviumRegister/Register.vue'
          ),
      },
    ],
  },
  {
    path: '/profit-calculator',
    name: 'profit-calculator',
    meta: { requiresAuth: true },
    beforeEnter: multipleGuards([
      subscriptionDataResolver,
      requiredSubscriptionGuard,
      requiredSellersGuard,
      initialDataLoadedGuard,
    ]),
    component: () =>
      import(
        /* webpackChunkName: "demo" */ '@/modules/DashboardLayout/ProfitCalculator/ProfitCalculatorRoute.vue'
      ),
  },
  {
    path: '/',
    redirect: 'dashboard',
    component: DashboardLayout,
    meta: { requiresAuth: true },
    beforeEnter: subscriptionDataResolver,
    children: [
      {
        path: '/add-card',
        name: 'add-card',
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/DashboardLayout/CreateSubscription/CreateSubscription.vue'
          ),
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        beforeEnter: multipleGuards([
          requiredSubscriptionGuard,
          requiredSellersGuard,
          initialDataLoadedGuard,
        ]),
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/DashboardLayout/ProfitDashboard/ProfitDashboard.vue'
          ),
      },

      {
        path: '/inventory-report',
        name: 'inventory-report',
        beforeEnter: multipleGuards([
          requiredSubscriptionGuard,
          requiredSellersGuard,
          initialDataLoadedGuard,
        ]),
        props: {
          pageFluid: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/DashboardLayout/InventoryReport/InventoryReport.vue'
          ),
      },
      {
        path: '/cogs',
        name: 'products',
        beforeEnter: multipleGuards([
          requiredSubscriptionGuard,
          requiredSellersGuard,
          initialDataLoadedGuard,
        ]),
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/DashboardLayout/ProductsAndCogs/Products.vue'
          ),
      },
      {
        path: '/manualExpenses',
        name: 'manualExpenses',
        beforeEnter: multipleGuards([
          requiredSubscriptionGuard,
          requiredSellersGuard,
          initialDataLoadedGuard,
        ]),
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/DashboardLayout/ManualExpenses/ManualExpenses.vue'
          ),
      },
      {
        path: '/categories',
        name: 'categories',
        beforeEnter: multipleGuards([
          requiredSubscriptionGuard,
          requiredSellersGuard,
          initialDataLoadedGuard,
        ]),
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/DashboardLayout/CustomCategories/Categories/Categories.vue'
          ),
      },
      {
        path: '/category/:category',
        name: 'category',
        beforeEnter: multipleGuards([
          requiredSubscriptionGuard,
          requiredSellersGuard,
          initialDataLoadedGuard,
        ]),
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/DashboardLayout/CustomCategories/Products/Products.vue'
          ),
      },
      {
        path: '/report/:reportId',
        name: 'CustomReport',
        beforeEnter: multipleGuards([
          requiredSubscriptionGuard,
          requiredSellersGuard,
          initialDataLoadedGuard,
        ]),
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/DashboardLayout/CustomReport/CustomReport.vue'
          ),
      },
      {
        path: '/sellerDataInProgress',
        name: 'sellerDataInProgress',
        beforeEnter: multipleGuards([
          requiredSubscriptionGuard,
          requiredSellersGuard,
        ]),
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/DashboardLayout/SellerDataInProgress/SellerDataInProgress.vue'
          ),
      },
      {
        path: '/settings',
        name: 'settings',
        beforeEnter: requiredSubscriptionGuard,
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/DashboardLayout/Settings/Settings.vue'
          ),
      },
      {
        path: '/settings/change-seller-name/:sellerId',
        props: true,
        name: 'changeSellerName',
        beforeEnter: requiredSubscriptionGuard,
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/DashboardLayout/Settings/ChangeSellerName/ChangeSellerNameRoute.vue'
          ),
      },
      {
        path: '/addSellerRedirect',
        name: 'addSellerRedirect',
        beforeEnter: requiredSubscriptionGuard,
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/DashboardLayout/AddSellerRedirect/AddSellerRedirect.vue'
          ),
      },
      {
        path: '/activateAdvertisingRedirect',
        name: 'activateAdvertisingRedirect',
        beforeEnter: requiredSubscriptionGuard,
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/DashboardLayout/ActivateAdvertisingRedirect/ActivateAdvertisingRedirect.vue'
          ),
      },
      {
        path: '/activateDspRedirect',
        name: 'activateDspRedirect',
        beforeEnter: requiredSubscriptionGuard,
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/DashboardLayout/ActivateDspRedirect/ActivateDspRedirect.vue'
          ),
      },

      {
        path: '/traffic-analytics',
        name: 'traffic-analytics',
        beforeEnter: multipleGuards([
          requiredSubscriptionGuard,
          requiredSellersGuard,
          initialDataLoadedGuard,
        ]),
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/DashboardLayout/TrafficAnalytics/TrafficAnalyticsRoute.vue'
          ),
      },
    ],
  },
  {
    path: '/',
    redirect: 'profitAndLossDemo',
    component: CustomReportsDemoLayout,
    children: [
      {
        path: '/profitAndLossDemo',
        name: 'profitAndLossDemo',
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/CustomReportsDemoLayout/PandL/PandL.vue'
          ),
      },
      {
        path: '/advertisingDemo',
        name: 'advertisingDemo',
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/CustomReportsDemoLayout/Advertising/Advertising.vue'
          ),
      },
      {
        path: '/marketShareDemo',
        name: 'marketShareDemo',
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/CustomReportsDemoLayout/MarketShare/MarketShare.vue'
          ),
      },
      {
        path: '/dayToDayDemo',
        name: 'dayToDayDemo',
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/CustomReportsDemoLayout/DayToDay/DayToDay.vue'
          ),
      },
      {
        path: '/refundsAnalyticsDemo',
        name: 'refundsAnalyticsDemo',
        component: () =>
          import(
            /* webpackChunkName: "demo" */ '@/modules/CustomReportsDemoLayout/RefundsAnalytics/RefundsAnalytics.vue'
          ),
      },
    ],
  },

  {
    path: '/msal-redirect',
    name: 'Msal Redirect',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "demo" */ '@/modules/MsalRedirect/MsalRedirectRoute.vue'
      ),
  },

  {
    path: '*',
    redirect: 'login',
  },
];

export default routes;
