<template>
  <div class="main-header">
    <v-container class="d-flex align-center">
      <a href="https://myrealprofit.com">
        <img
          src="@/assets/svg/logo.svg"
          class="logo-img"
          alt="MRP logo"
          width="100px"
          height="40px"
        />
      </a>
      <v-spacer></v-spacer>
      <router-link to="/login">
        <v-btn
          class="login-btn px-6 mr-2 mr-md-2"
          small
          depressed
          outlined
          rounded
        >
          Sign In
        </v-btn>
      </router-link>

      <v-btn to="/register" color="secondary" class="px-6" small rounded>
        Start free trial
      </v-btn>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'MainHeader',
};
</script>

<style scoped>
.main-header {
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  z-index: 10;
  background: var(--white);
  box-shadow: 0 1px 3px 0.5px #848389;
}
.login-btn.v-btn--outlined {
  border: 1px solid #d7d6dc;
}
.logo-img {
  display: block;
}
</style>
