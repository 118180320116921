import { getPrettyInteger } from '@/common/helpers/helpers.js';

class Profit {
  constructor(total = 0, margin = 0, roi = 0) {
    this._total = total;
    this._totalPretty = getPrettyInteger(total);

    this._margin = margin;
    this._roi = roi;
  }

  get total() {
    return this._total;
  }
  get totalPretty() {
    return this._totalPretty;
  }

  get margin() {
    return this._margin;
  }
  get roi() {
    return this._roi;
  }
}

export { Profit };
