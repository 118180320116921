class Timer {
  constructor(callback, delay) {
    // id, started, running;

    this.callback = callback;
    this.remaining = delay;

    this.start();
  }

  start() {
    this.running = true;
    this.started = new Date();
    this.id = setTimeout(this.callback, this.remaining);
  }

  pause() {
    this.running = false;
    clearTimeout(this.id);
    this.remaining -= new Date() - this.started;
  }

  get timeLeft() {
    if (this.running) {
      this.pause();
      this.start();
    }

    return this.remaining;
  }

  get stateRunning() {
    return this.running;
  }
}

export { Timer };
