<template>
  <div class="sidenav">
    <img
      src="@/assets/svg/logo.svg"
      alt="MRP logo"
      class="sidenav__logo mb-lg-13"
    />

    <div class="sidenav__sellers" v-if="!sellers.length">
      <p class="sidenav__text ml-3 ml-lg-0 mb-0 mb-lg-13">
        Connect Amazon Account
      </p>
    </div>

    <v-select
      v-model="activeSeller"
      :items="sellers"
      hide-details
      outlined
      dense
      :menu-props="{ offsetY: true }"
      solo
      append-icon="mdi-chevron-down"
      return-object
      single-line
      @change="onSellerChange($event)"
      class="mb-lg-13 ml-5 ml-lg-0 app-sellers-select"
      v-if="sellers.length"
      item-text="name"
      item-value="id"
    >
    </v-select>

    <v-btn icon class="ml-auto d-lg-none" @click="toggleSidebarItems()">
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <div
      class="sidenav__items"
      :class="{ 'sidenav__items--active': sidebarItems }"
    >
      <h3 class="sidenav__title">Tools</h3>
      <ul class="nav mb-5">
        <li v-for="item in adminItems" :key="item.text">
          <sidebar-link :item="item">
            {{ item.afterIconName }}
            <template v-slot:after v-if="item.afterIconName">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-3"
                    dense
                    color="orange"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.afterIconName }}
                  </v-icon>
                </template>
                <span>{{ item.afterTooltipText }}</span>
              </v-tooltip>
            </template>
          </sidebar-link>
        </li>
      </ul>
      <ul class="nav mb-13">
        <li v-for="item in productItems" :key="item.text">
          <sidebar-link :item="item">
            {{ item.afterIconName }}
            <template v-slot:after v-if="item.afterIconName">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-3"
                    dense
                    color="orange"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.afterIconName }}
                  </v-icon>
                </template>
                <span>{{ item.afterTooltipText }}</span>
              </v-tooltip>
            </template>
          </sidebar-link>
        </li>
      </ul>

      <template v-if="hasCustomReports">
        <h3 class="sidenav__title">Custom Reports</h3>
        <ul class="nav mb-13">
          <li v-for="item in customReportItems" :key="item.text">
            <sidebar-link :item="item"></sidebar-link>
          </li>
        </ul>
      </template>

      <h3 class="sidenav__title">Account</h3>
      <ul class="nav">
        <li v-for="item in accountItems" :key="item.text">
          <sidebar-link :item="item"></sidebar-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SidebarLink from '@/common/components/SidebarLink';
import store from '@/store';

export default {
  name: 'SidebarNav',
  components: {
    SidebarLink,
  },
  data() {
    return {
      accountItems: [
        {
          text: 'Settings',
          iconName: '$vuetify.icons.settings',
          path: '/settings',
          includedPaths: [
            '/add-card',
            '/settings',
            '/addSellerRedirect',
            '/activateAdvertisingRedirect',
          ],
        },
        {
          text: 'Log Out',
          iconName: '$vuetify.icons.signOut',
          callback: () => {
            this.logout();
          },
        },
      ],
      sidebarItems: false,
    };
  },
  watch: {
    $route() {
      document.documentElement.classList.remove('disable-scroll');
      this.sidebarItems = false;
    },
  },
  methods: {
    onSellerChange(seller) {
      if (seller.id !== this.activeSeller) {
        if (this.$route.path === '/dashboard') {
          this.$store.dispatch('dashboard_change_seller_controller', {
            sellerId: seller.id,
            sellerName: seller.name,
            sellerAdvertisingAuthorized: seller.advertisingAuthorized,
            sellerState: seller.state,
          });
        } else {
          this.$store.dispatch('change_seller_controller', {
            sellerId: seller.id,
            sellerName: seller.name,
            sellerAdvertisingAuthorized: seller.advertisingAuthorized,
            sellerState: seller.state,
          });
        }
      }
    },
    logout() {
      document.documentElement.classList.remove('disable-scroll');
      this.sidebarItems = false;
      this.$store.dispatch('logout');
    },
    toggleSidebarItems() {
      if (this.sidebarItems) {
        document.documentElement.classList.remove('disable-scroll');
      } else {
        document.documentElement.classList.add('disable-scroll');
      }
      this.sidebarItems = !this.sidebarItems;
    },
  },
  computed: {
    sellers() {
      return this.$store.getters.sellers;
    },
    activeSeller: {
      get() {
        return store.getters.currentSellerId;
      },
      set(seller) {
        return seller;
      },
    },
    adminItems() {
      return [
        {
          text: 'Profit Dashboard',
          iconName: '$vuetify.icons.profitDashboard',
          path: '/dashboard',
        },
        {
          text: 'Profit Calculator',
          iconName: '$vuetify.icons.profitCalculator',
          path: '/profit-calculator',
        },
        {
          text: 'FBA Inventory',
          iconName: '$vuetify.icons.fbaInventory',
          path: '/inventory-report',
        },
        {
          text: 'Traffic Analytics',
          iconName: '$vuetify.icons.trafficAnalytics',
          path: '/traffic-analytics',
        },
      ];
    },
    productItems() {
      return [
        {
          text: 'Products and COGS',
          iconName: '$vuetify.icons.productsAndCogs',
          afterIconName:
            store.getters.currentSellerHasMissingActiveCogs && 'mdi-alert',
          afterTooltipText: 'Some of the Active listings have empty COGS value',
          path: '/cogs',
        },
        {
          text: 'Custom Categories',
          iconName: '$vuetify.icons.customCategories',
          path: '/categories',
          includedNames: ['category'],
        },
        {
          text: 'Manual Expenses',
          iconName: '$vuetify.icons.manualExpenses',
          path: '/manualExpenses',
        },
      ];
    },
    customReportItems() {
      return this.$store.getters['customReports/reports'];
    },
    hasCustomReports() {
      return !!this.customReportItems?.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .sidenav__items {
    position: absolute;
    top: 100%;
    width: 100%;
    padding: 16px 32px;
    background: white;
    height: calc(100vh - var(--sidebar-mobile-height));
    overflow: auto;
    left: 0;
    display: none;
    &--active {
      display: block;
    }
  }
}

.sidenav {
  background: white;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 10;
  padding: 20px 16px 20px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    position: fixed;
    width: var(--sidebar-width);
    height: 100vh;
    border-right: 1px solid #e7eaf3;
    padding: 40px 30px;
    display: block;
    box-shadow: none;
    left: 0;
    overflow-y: scroll;
  }

  &__logo {
    width: 86px;
    height: 34px;
    display: block;
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      width: 118px;
      height: 46px;
      margin: 0 auto;
    }
  }

  &__title {
    font: 13px/1em Rajdhani-Medium;
    text-transform: uppercase;
    text-align: unset;
    color: var(--main-gray-color);
    margin: 0 0 12px 0;
  }

  &__text {
    font-weight: bold;
    color: var(--main-gray-color);
  }
}

.app-sellers-select {
  max-width: min(50%, 200px);
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    max-width: none;
  }
}

.nav {
  margin: 0;
  padding: 0;
  text-align: left;
  list-style: none;
  margin: 0 -15px;
}

.sidenav::-webkit-scrollbar {
  display: none; /* Chrome */
}
.sidenav {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
