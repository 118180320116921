<template>
  <div class="space-divider" :style="computedStyles"></div>
</template>

<script>
export default {
  name: 'VerticalSpaceDivider',
  props: ['config'],
  data() {
    return {
      // default styles
      height: '32px',
    };
  },
  created() {
    this.setConfigData();
  },
  methods: {
    setConfigData() {
      if (this.config) {
        if (this.config.height) {
          this.height = this.config.height;
        }
      }
    },
  },
  computed: {
    computedStyles: function() {
      return [
        {
          height: this.height,
        },
      ];
    },
  },
};
</script>

<style scoped>
.space-divider {
  height: 32px;
}
</style>
