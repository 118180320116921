import { DateTime } from 'luxon';
import { mapRangeTypeToDatesInLAZone } from '@/common/helpers/map-range-type';

const state = {
  currentRangeType: 'today',
  currentRangeDates: mapRangeTypeToDatesInLAZone('today'),
};

export function getCurrentRangeDates(rangeType, customDates) {
  if (rangeType === 'custom') {
    return customDates;
  }

  return mapRangeTypeToDatesInLAZone(rangeType);
}

export function getCurrentRangeDatesDashboard(rangeType, customDates) {
  const { start, end } = getCurrentRangeDates(rangeType, customDates);

  return {
    start: DateTime.fromISO(start)
      .setZone('America/Los_Angeles')
      .toFormat('yyyy-MM-dd'),
    end: DateTime.fromISO(end)
      .setZone('America/Los_Angeles')
      .toFormat('yyyy-MM-dd'),
  };
}

const getters = {
  currentRangeType: state => state.currentRangeType,
  currentRangeDates: state => state.currentRangeDates,
};

const mutations = {
  SET_CURRENT_RANGE(state, { type, dates }) {
    state.currentRangeType = type;
    state.currentRangeDates = dates;
  },
};

const actions = {
  clear_dateranges({ commit }) {
    const currentRange = {
      type: 'today',
      dates: mapRangeTypeToDatesInLAZone('today'),
    };
    commit('SET_CURRENT_RANGE', currentRange);
  },

  change_current_range({ commit, dispatch }, { type, dates }) {
    commit('SET_CURRENT_RANGE', { type, dates });

    dispatch('fetch_dashboard_totals_controller');
    dispatch('fetch_dashboard_products_controller');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
