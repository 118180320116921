import { ProductVariation } from '@/store/classes/productVariation';

class ProductVariations {
  constructor() {
    this.dict = {};
    this.list = [];
  }

  /* * * * * * * GETTERS * * * * * * * * */
  get list() {
    return this._list;
  }

  get dict() {
    return this._dict;
  }
  /* * * * * * * * * * * * * * * * * * * */

  /* * * * * * * SETTERS * * * * * * * * */
  set list(val) {
    this._list = val;
  }

  set dict(val) {
    this._dict = val;
  }
  /* * * * * * * * * * * * * * * * * * * */

  injectProductVariation(backendProductVariation, category) {
    let productVariation = new ProductVariation();
    productVariation.injectBestseller(backendProductVariation, category);

    backendProductVariation.products.forEach(backendSubproduct => {
      productVariation.injectSubproduct(backendSubproduct);
    });

    this.list.push(productVariation);
  }

  finalPreparation() {
    this.list.forEach(productVariation => {
      productVariation.variationTotal.expenses.finalPreparation(
        productVariation.variationTotal.sales.total
      );
      productVariation.subproducts.sort();
    });
  }
}

export { ProductVariations };
