import * as fetcher from '@/api/fetcher';
import * as poster from '@/api/poster';

const state = {
  subscriptionLoaded: false,
  subscriptionStatus: null, // https://stripe.com/docs/api/subscriptions/object#subscription_object-status
  subscriptionDetails: {},
};

const getters = {
  subscriptionLoaded: state => state.subscriptionLoaded,
  subscriptionStatus: state => state.subscriptionStatus,
  subscriptionDetails: state => state.subscriptionDetails,
  subscriptionActive: state =>
    state.subscriptionStatus === 'active' ||
    state.subscriptionStatus === 'trialing',
  subscriptionOverdue: state => state.subscriptionStatus === 'past_due',
};

const mutations = {
  SET_SUBSCRIPTION_STATUS(state, status) {
    state.subscriptionStatus = status;
    state.subscriptionLoaded = true;
  },

  SET_SUBSCRIPTION_DETAILS(state, details) {
    state.subscriptionDetails = details;
  },

  CLEAR_SUBSCRIPTION(state) {
    state.subscriptionStatus = null;
    state.subscriptionDetails = {};
    state.subscriptionLoaded = false;
  },
};

const actions = {
  clear_subscriptions({ commit }) {
    commit('CLEAR_SUBSCRIPTION');
  },

  // payload: { token }
  update_card_details_controller(state, payload) {
    return poster.update_card_details(payload.token);
  },

  async get_subscription_controller({ commit }) {
    const data = await fetcher.fetch_subscriptions();
    commit('SET_SUBSCRIPTION_STATUS', data.status);
    commit('SET_SUBSCRIPTION_DETAILS', data.subscriptionDetails);
  },

  cancel_subscription_controller({ dispatch }) {
    poster
      .cancel_subscription()
      .then(() => {
        dispatch('logInfoSuccess', {
          msg: 'You successfully canceled your subscription.',
        });
        dispatch('get_subscription_controller');
      })
      .catch(() => {
        dispatch('logAlertError', {
          msg:
            'There was an error canceling your subscription. Please, try again.',
        });
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
