import { DateTime } from 'luxon';
import router from '@/router';

import * as fetcher from '@/api/fetcher';
import * as poster from '@/api/poster';
import { mrpDataLayer } from '@/analytics/gtm';

const state = {
  currentSellerStatus: 'empty', // empty, dbInProgress, active
  currentSeller: {
    id: null,
    name: null,
    advertisingAuthorized: null,
    state: null,
  },

  currentSellerDetailsStatus: 'empty', // empty, dbInProgress, active
  currentSellerDetails: {
    spmwsHistoryInitialized: null,
    ppcHistoryInitialized: null,
    hasMissingActiveCogs: null,
    sellerDataInitialized: null,
    dataAccessStartFrom: null,
  },

  sellersStatus: 'empty', // empty, fetching, fetched
  sellers: [],

  selectedCategory: 'Parent ASIN',
  defaultCategories: ['SKU', 'Parent ASIN'],
  customCategories: [],
};

const getters = {
  currentSeller: state => state.currentSeller,
  currentSellerStatus: state => state.currentSellerStatus,
  currentSellerId: state => state.currentSeller.id,
  currentSellerName: state => state.currentSeller.name,
  currentSellerAdvertisingAuthorized: state =>
    state.currentSeller.advertisingAuthorized,
  currentSellerState: state => state.currentSeller.state,

  currentSellerDetails: state => state.currentSellerDetails,
  currentSellerHasMissingActiveCogs: state =>
    state.currentSellerDetails.hasMissingActiveCogs,
  currentSellerDetailsStatus: state => state.currentSellerDetailsStatus,

  sellersLoaded: state => state.sellersStatus === 'fetched',
  sellersStatus: state => state.sellersStatus,
  sellers: state => state.sellers,
  sellerById: state => id => {
    return state.sellers.find(seller => seller.id == id);
  },

  selectedCategory: state => state.selectedCategory,
  customCategories: state => state.customCategories,
  allCategories: state => [
    ...state.defaultCategories,
    ...state.customCategories,
  ],
};

const mutations = {
  SET_CURRENT_SELLER(state, seller) {
    state.currentSeller = {
      id: seller.id,
      name: seller.name,
      advertisingAuthorized: seller.advertisingAuthorized,
      state: seller.state,
    };
  },

  SET_CURRENT_SELLER_STATUS(state, status) {
    state.currentSellerStatus = status;
  },

  SET_CURRENT_SELLER_DETAILS(state, sellerDetails) {
    state.currentSellerDetails = {
      spmwsHistoryInitialized: sellerDetails.spmwsHistoryInitialized,
      ppcHistoryInitialized: sellerDetails.ppcHistoryInitialized,
      hasMissingActiveCogs: !sellerDetails.sellerDataInitialized
        ? false
        : sellerDetails.hasMissingActiveCogs,
      sellerDataInitialized: sellerDetails.sellerDataInitialized,
      dataAccessStartFrom: DateTime.fromISO(
        sellerDetails.dataAccessStartFrom.replace('Z', ''),
        { zone: 'America/Los_Angeles' }
      )
        .toUTC()
        .toISO(),
    };
  },

  SET_CURRENT_SELLER_DETAILS_STATUS(state, status) {
    state.currentSellerDetailsStatus = status;
  },

  SET_SELLERS(state, sellers) {
    state.sellers = sellers;
  },

  SET_SELLERS_STATUS(state, status) {
    state.sellersStatus = status;
  },

  SET_SELECTED_CATEGORY(state, selectedCategory) {
    state.selectedCategory = selectedCategory;
  },

  SET_CATEGORIES(state, categories) {
    state.customCategories = [...categories];
  },
  ADD_CATEGORY(state, category) {
    state.customCategories.push(category);
  },
  REMOVE_CATEGORY(state, category) {
    state.customCategories = state.customCategories.filter(
      item => item !== category
    );
  },

  CLEAR_SELLERS(state) {
    state.sellers = [];
  },

  CLEAR_CURRENT_SELLER(state) {
    state.currentSeller = {
      id: null,
      name: null,
      advertisingAuthorized: null,
      state: null,
    };
  },

  CLEAR_CURRENT_SELLER_DETAILS(state) {
    state.currentSellerDetails = {
      spmwsHistoryInitialized: null,
      ppcHistoryInitialized: null,
      hasMissingActiveCogs: null,
      sellerDataInitialized: null,
      dataAccessStartFrom: null,
    };
  },

  CLEAR_CATEGORIES(state) {
    state.selectedCategory = 'Parent ASIN';
    state.customCategories = [];
  },
};

const actions = {
  clear_sellers_and_status({ commit }) {
    commit('SET_CURRENT_SELLER_STATUS', 'empty');
    commit('SET_CURRENT_SELLER_DETAILS_STATUS', 'empty');
    commit('SET_SELLERS_STATUS', 'empty');

    commit('CLEAR_CURRENT_SELLER');
    commit('CLEAR_CURRENT_SELLER_DETAILS');
    commit('CLEAR_SELLERS');

    commit('CLEAR_CATEGORIES');
  },

  async set_current_seller(
    { commit, dispatch },
    { id, name, advertisingAuthorized, state }
  ) {
    commit('SET_CURRENT_SELLER', {
      id,
      name,
      advertisingAuthorized,
      state,
    });
    await dispatch('change_loading_state', true);
    await dispatch('fetch_seller_details_controller', {
      amazonSellingPartnerId: id,
    });
    await dispatch('fetch_custom_categories_controller');
    await dispatch('change_loading_state', false);
  },

  async dashboard_change_seller_controller(
    { commit, dispatch },
    { sellerId, sellerName, sellerAdvertisingAuthorized, sellerState }
  ) {
    commit('SET_CURRENT_SELLER_STATUS', 'fetching');
    await dispatch('set_current_seller', {
      id: sellerId,
      name: sellerName,
      advertisingAuthorized: sellerAdvertisingAuthorized,
      state: sellerState,
    });
    dispatch('created_dashboard_controller');
    commit('SET_CURRENT_SELLER_STATUS', 'active');
  },

  async change_seller_controller(
    { commit, dispatch },
    { sellerId, sellerName, sellerAdvertisingAuthorized, sellerState }
  ) {
    commit('SET_CURRENT_SELLER_STATUS', 'fetching');
    await dispatch('set_current_seller', {
      id: sellerId,
      name: sellerName,
      advertisingAuthorized: sellerAdvertisingAuthorized,
      state: sellerState,
    });
    commit('SET_CURRENT_SELLER_STATUS', 'active');
  },

  async fetch_sellers_controller({ commit, dispatch, getters }) {
    let { id, name, advertisingAuthorized, state } = getters.currentSeller;

    dispatch('clear_sellers_and_status');
    commit('SET_SELLERS_STATUS', 'fetching');
    const sellers = await fetcher.fetch_sellers();
    commit('SET_SELLERS', sellers);

    if (sellers.length > 0) {
      // Set seller from local storage or first from api
      await dispatch('set_current_seller', {
        id: id || sellers[0].id,
        name: name || sellers[0].name,
        advertisingAuthorized:
          advertisingAuthorized || sellers[0].advertisingAuthorized,
        state: state || sellers[0].state,
      });
      // TODO: Reduce amount of actions
      dispatch('clear_dashboard_and_status');

      commit('SET_CURRENT_SELLER_STATUS', 'active');
    }
    commit('SET_SELLERS_STATUS', 'fetched');
  },

  async fetch_seller_details_controller(
    { commit },
    { amazonSellingPartnerId }
  ) {
    commit('CLEAR_CURRENT_SELLER_DETAILS');
    commit('SET_CURRENT_SELLER_DETAILS_STATUS', 'fetching');
    let data = await fetcher.fetch_seller_details({ amazonSellingPartnerId });
    data.hasMissingActiveCogs = await fetcher.fetch_has_missing_cogs({
      amazonSellingPartnerId,
    });
    commit('SET_CURRENT_SELLER_DETAILS', data);
    commit('SET_CURRENT_SELLER_DETAILS_STATUS', 'active');
  },

  async fetch_custom_categories_controller({ commit }) {
    commit('CLEAR_CATEGORIES');
    let data = await fetcher.fetch_custom_categories();
    commit('SET_CATEGORIES', data);
  },

  async change_group_by_controller({ commit, dispatch }, sellerCategory) {
    commit('SET_SELECTED_CATEGORY', sellerCategory);
    if (sellerCategory === 'SKU') return;
    await dispatch('fetch_dashboard_products_controller');
  },

  post_seller_controller({ dispatch }, body) {
    poster
      .post_seller(body)
      .then(() => {
        mrpDataLayer.authenticated.push({
          event: 'Seller connected successfully',
        });
        dispatch('logAlertSuccess', {
          msg:
            'Your Seller Account has been successfully connected. Please, now connect your PPC account.',
          delay: 10000,
        });
        dispatch('fetch_sellers_controller');
        router.push({ name: 'settings' });
      })
      .catch(error => {
        router.push({ name: 'settings' });
        if (
          error.response !== null &&
          error.response.data !== null &&
          error.response.data.description !== null
        ) {
          dispatch('logAlertError', {
            msg: error.response.data.description,
            delay: 15000,
          });
        } else {
          dispatch('logAlertError', {
            msg:
              'There was an error renewing your Seller Account token. Please, try again or contact support.',
            delay: 15000,
          });
        }
      });
  },

  renew_token_controller({ dispatch }, body) {
    dispatch('change_loading_state', true);
    poster
      .renew_token(body)
      .then(() => {
        dispatch('change_loading_state', false);
        dispatch('logAlertSuccess', {
          msg: 'Your Seller Account token has been renewed successfully.',
          delay: 7000,
        });
        router.push({ name: 'settings' });
      })
      .catch(error => {
        router.push({ name: 'settings' });
        if (
          error.response !== null &&
          error.response.data !== null &&
          error.response.data.description !== null
        ) {
          dispatch('logAlertError', {
            msg: error.response.data.description,
            delay: 15000,
          });
        } else {
          dispatch('logAlertError', {
            msg:
              'There was an error renewing your Seller Account token. Please, try again or contact support.',
            delay: 15000,
          });
        }
      });
  },

  post_advertising_controller({ dispatch }, body) {
    poster
      .post_advertising(body)
      .then(() => {
        mrpDataLayer.authenticated.push({
          event: 'Advertising connected successfully',
        });

        dispatch('logAlertSuccess', {
          msg:
            'Connection complete. We have started downloading your data. You will receive an email notification once your account is ready to use.',
          delay: 10000,
        });
        dispatch('fetch_sellers_controller');
        router.push({ name: 'settings' });
      })
      .catch(error => {
        router.push({ name: 'settings' });
        if (
          error.response !== null &&
          error.response.data !== null &&
          error.response.data.description !== null
        ) {
          dispatch('logAlertError', {
            msg: error.response.data.description,
            delay: 15000,
          });
        } else {
          dispatch('logAlertError', {
            msg:
              'There was an error adding your PPC Acccount. Please, try again or contact support.',
            delay: 15000,
          });
        }
      });
  },
  post_dsp_controller({ dispatch }, body) {
    poster
      .post_dsp(body)
      .then(() => {
        dispatch('logAlertSuccess', {
          msg: 'You successfully connected your DSP account.',
          delay: 10000,
        });
        router.push({ name: 'settings' });
      })
      .catch(error => {
        router.push({ name: 'settings' });
        if (
          error.response !== null &&
          error.response.data !== null &&
          error.response.data.description !== null
        ) {
          dispatch('logAlertError', {
            msg: error.response.data.description,
            delay: 15000,
          });
        } else {
          dispatch('logAlertError', {
            msg:
              'There was an error adding your DSP Acccount. Please, try again or contact support.',
            delay: 15000,
          });
        }
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
