<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.4 4H18.6C19.9255 4 21 5.05824 21 6.36364V16.6364C21 17.9418 19.9255 19 18.6 19H5.4C4.07452 19 3 17.9418 3 16.6364V6.36364C3 5.05824 4.07452 4 5.4 4ZM18 7.52941H6C5.5 7.52941 5 7.08824 5 6.64706C5 6.20588 5.6 5.76471 6 5.76471H18C18.5 5.76471 19 6.15975 19 6.64706C19 7.13437 18.5 7.52941 18 7.52941Z"
      class="app-fill-current"
    />
  </svg>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'icon-dashboard',
});
</script>
