import * as luxon from 'luxon';
import { DateTime } from 'luxon';
import store from '@/store';
import { $api } from '@/main';

export const TrafficAnalyticsApi = {
  async get({ dates }) {
    const startDate = DateTime.fromISO(dates.start).toFormat('yyyy-MM-dd');
    const endDate = DateTime.fromISO(dates.end).toFormat('yyyy-MM-dd');

    const params = {
      sellerId: store.getters.currentSellerId,
      marketplaceId: store.getters.currentMarketplaceId,
      startDate,
      endDate,
    };

    return $api.get('traffic/total', 'traffic/total', { params });
  },

  async getProducts({ dates, groupBy }) {
    const startDate = DateTime.fromISO(dates.start).toFormat('yyyy-MM-dd');
    const endDate = DateTime.fromISO(dates.end).toFormat('yyyy-MM-dd');

    const params = {
      sellerId: store.getters.currentSellerId,
      marketplaceId: store.getters.currentMarketplaceId,
      start: startDate,
      end: endDate,
      groupBy,
    };

    return $api.make_post(
      'traffic/total-by-product',
      'traffic/total-by-product',
      params
    );
  },

  async getChart({ dates, asins }) {
    const startDate = luxon.DateTime.fromISO(dates.start).toFormat(
      'yyyy-MM-dd'
    );
    const endDate = luxon.DateTime.fromISO(dates.end).toFormat('yyyy-MM-dd');

    const params = {
      sellerId: store.getters.currentSellerId,
      marketplaceId: store.getters.currentMarketplaceId,
      start: startDate,
      end: endDate,
      asins,
    };

    return $api.make_post('traffic/daily-chart', 'traffic/daily-chart', params);
  },
};
