import { Product } from '@/store/classes/product';

class Products {
  constructor() {
    this._dict = {};
    this._list = [];
  }

  get list() {
    return this._list;
  }

  get dict() {
    return this._dict;
  }

  set list(val) {
    this._list = val;
  }

  injectProduct(backendProduct) {
    let _product = new Product(
      'CHILD: ' + backendProduct.asin + ' / SKU: ' + backendProduct.sku,
      backendProduct.asin,
      backendProduct.name,
      backendProduct.variation,
      backendProduct.img,
      backendProduct.averagePrice
    );
    _product.injectSales(backendProduct.sales);
    _product.injectUnits(backendProduct.units);
    _product.injectExpenses(backendProduct.expenses);
    _product.injectKpis(backendProduct.kpis);

    this.addProduct(_product);
  }

  addProduct(product) {
    if (product instanceof Product) {
      this.list.push(product);
    }
  }

  sort() {
    this.list = this.list.sort((a, b) => {
      return a.sales.total > b.sales.total ? -1 : 1;
    });
  }
}

export { Products };
