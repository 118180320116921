<template>
  <router-link
    v-if="item.path"
    :to="item.path"
    v-slot="{ href, navigate, isActive }"
  >
    <v-hover v-slot="{ hover }">
      <v-btn
        :to="item.path"
        elevation="0"
        :color="buttonColor(isActive)"
        :text="!(isActive || includedPathActive() || includedNameActive())"
        block
        class="justify-start px-3 app-sidebar-link"
      >
        <v-icon
          class="mr-3 sidebar-icon-width"
          :class="iconClass(isActive, hover)"
        >
          {{ item.iconName }}
        </v-icon>
        {{ item.text }}
        <v-chip
          v-if="item.chip"
          small
          :color="item.chip.backgroundColor"
          :text-color="item.chip.textColor"
          class="ma-2 rounded px-2 py-0"
        >
          {{ item.chip.text }}
        </v-chip>
        <slot name="after"></slot>
      </v-btn>
    </v-hover>
  </router-link>
  <v-hover v-else v-slot="{ hover }">
    <v-btn
      @click="item.callback"
      block
      elevation="0"
      text
      class="justify-start px-3 app-sidebar-link"
      :color="buttonColor(false)"
    >
      <v-icon class="mr-3 sidebar-icon-width" :class="iconClass(false, hover)">
        {{ item.iconName }}
      </v-icon>
      {{ item.text }}
      <slot name="after"></slot>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: 'SidebarLink',
  components: {},
  props: ['item'],
  methods: {
    includedPathActive() {
      if (!this.item.includedPaths) {
        return false;
      }
      return this.item.includedPaths.includes(this.$route.path);
    },
    includedNameActive() {
      if (!this.item.includedNames) {
        return false;
      }
      return this.item.includedNames.includes(this.$route.name);
    },
    buttonColor(isActive) {
      return isActive || this.includedPathActive() || this.includedNameActive()
        ? 'primary'
        : '';
    },
    iconClass(isActive, isHover) {
      const isRouteActive =
        isActive || this.includedPathActive() || this.includedNameActive();
      return [
        !isRouteActive
          ? isHover
            ? 'grey--text'
            : 'grey--text text--lighten-1'
          : '',
      ];
    },
  },
};
</script>
<style scoped>
.app-sidebar-link {
  font-weight: 600;
  font-size: 0.875rem !important;
}
.sidebar-icon-width {
  width: 22px;
}
</style>
