<template>
  <div class="auth-layout-container">
    <!-- HEADER is sticky. Add indent for the underlying content -->
    <main-header></main-header>
    <!-- <main-header-indent></main-header-indent> -->

    <vertical-space-divider
      :config="{ height: '32px' }"
    ></vertical-space-divider>

    <v-container>
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <router-view></router-view>
      </fade-transition>
    </v-container>

    <main-footer></main-footer>
  </div>
</template>
<script>
import MainHeader from '@/common/components/MainHeader.vue';
import MainFooter from '@/common/components/MainFooter.vue';
import { FadeTransition } from 'vue2-transitions';
import VerticalSpaceDivider from '@/common/components/VerticalSpaceDivider.vue';
// import MainHeaderIndent from '@/common/components/MainHeaderIndent.vue';

export default {
  name: 'AuthLayout',
  components: {
    MainHeader,
    MainFooter,
    FadeTransition,
    VerticalSpaceDivider,
    // MainHeaderIndent,
  },
};
</script>

<style lang="scss" scoped>
.auth-layout-container {
  background: #ffffff;
  min-height: 100%;
}

.auth-component-container {
  width: min(100%, 1100px);
  padding: 0 10px;
}
</style>
