import { fetch_product_details } from '@/api/fetcher';

const state = {
  all: {
    data: [],
    status: null, // success, error, loading
    error: null,
  },
  one: {
    data: [],
    status: null, // success, error, loading
    error: null,
  },
};

const getters = {
  allData: state => state.all.data,
  allIsLoading: state => state.all.status === 'loading',
  allIsSuccess: state => state.all.status === 'success',
  allStatus: state => state.all.status,
  allSkus: state => state.all.data.map(product => product.sku),
};

const mutations = {
  allLoading(state) {
    state.all.status = 'loading';
    state.all.error = null;
    state.all.data = [];
  },
  allSuccess(state, products) {
    state.all.data = products;
    state.all.status = 'success';
  },
  allFailure(state, { error }) {
    state.all.error = error;
    state.all.status = 'error';
  },
};

const actions = {
  async loadAll({ commit }) {
    commit('allLoading');

    try {
      const products = await fetch_product_details();
      commit('allSuccess', products);
    } catch (error) {
      commit('allFailure', { error });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
