import store from '@/store';
import { $api } from '@/main';
import { env_vars } from '@/store/vars';

function edit_product_tag(category, sku, asin, productTag) {
  const params = {
    sellerId: store.getters.currentSellerId,
    marketplaceId: store.getters.currentMarketplaceId,
    category: category,
    sku: sku,
    asin: asin,
    productTag: productTag,
  };

  return $api.post('custom-category/edit-product', null, { params });
}

function delete_category(category) {
  const params =
    'sellerId=' +
    store.getters.currentSellerId +
    '&marketplaceId=' +
    store.getters.currentMarketplaceId +
    '&category=' +
    category;
  return $api.delete('custom-category/delete?' + params);
}

function post_cogs(sku, asin, newPrice, startDate) {
  const params = {
    currencyCode: store.getters.currentCurrencyCode,
    sellerId: store.getters.currentSellerId,
    sku: sku,
    asin: asin,
    cogsValue: newPrice,
    startDate: startDate,
  };
  return $api.post('cogs/update', null, { params });
}

function add_manual_expense(type, description, price, startDate) {
  const params = {
    marketplaceId: store.getters.currentMarketplaceId,
    sellingPartnerId: store.getters.currentSellerId,
    type: type,
    description: description,
    price: price,
    startDate: startDate,
  };
  return $api.post('addAmazonManualExpense', null, { params });
}

function delete_manual_expense(id) {
  const params =
    'sellingPartnerId=' +
    store.getters.currentSellerId +
    '&marketplaceId=' +
    store.getters.currentMarketplaceId +
    '&id=' +
    id;
  return $api.delete('deleteAmazonManualExpense?' + params);
}

function update_manual_expense_start_date(id, newStartDate) {
  const params = {
    marketplaceId: store.getters.currentMarketplaceId,
    sellingPartnerId: store.getters.currentSellerId,
    id: id,
    startDate: newStartDate,
  };
  return $api.post('setAmazonManualExpenseStartDate', null, { params });
}

function update_manual_expense_end_date(id, newEndDate) {
  const params = {
    marketplaceId: store.getters.currentMarketplaceId,
    sellingPartnerId: store.getters.currentSellerId,
    id: id,
    endDate: newEndDate,
  };
  return $api.post('setAmazonManualExpenseEndDate', null, { params });
}

function post_product_categories_csv(file, category) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  const formData = new FormData();
  formData.append('file', file);
  formData.append('marketplaceId', store.getters.currentMarketplaceId);
  formData.append('sellerId', store.getters.currentSellerId);
  formData.append('category', category);
  return $api.post('custom-category/csv/upload', formData, config);
}

function post_products_and_cogs_csv(file) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  const formData = new FormData();
  formData.append('file', file);
  formData.append('currencyCode', store.getters.currentCurrencyCode);
  formData.append('sellerId', store.getters.currentSellerId);
  return $api.post('cogs/csv/upload', formData, config);
}

function post_seller(body) {
  const config = {
    headers: { 'Content-Type': 'application/json' },
  };
  let path = 'amazon-selling-partner-authorize';
  path += env_vars.isBeta ? '?isBeta=true' : '';
  return $api.post(path, body, config);
}

function renew_token(body) {
  const config = {
    headers: { 'Content-Type': 'application/json' },
  };
  let path = 'amazon-selling-partner-refresh-token';
  path += env_vars.isBeta ? '?isBeta=true' : '';
  return $api.post(path, body, config);
}

function post_advertising(body) {
  const config = {
    headers: { 'Content-Type': 'application/json' },
  };
  let path = 'amazon-advertising-authorize';
  path += env_vars.isBeta ? '?isBeta=true' : '';
  return $api.post(path, body, config);
}

function post_dsp(body) {
  const config = {
    headers: { 'Content-Type': 'application/json' },
  };
  let path = 'amazon-dsp-authorize';
  path += env_vars.isBeta ? '?isBeta=true' : '';
  return $api.post(path, body, config);
}

function update_card_details(stripe_token) {
  const params = {
    token: stripe_token,
  };
  return $api.post('payments/change-payment-method', null, { params });
}

function cancel_subscription() {
  return $api.post('payments/cancel-subscription', null, null);
}

export {
  edit_product_tag,
  delete_category,
  post_cogs,
  add_manual_expense,
  delete_manual_expense,
  update_manual_expense_start_date,
  update_manual_expense_end_date,
  post_product_categories_csv,
  post_products_and_cogs_csv,
  post_seller,
  renew_token,
  post_advertising,
  post_dsp,
  update_card_details,
  cancel_subscription,
};
