<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 6.37746L11.5 10L4 6.37746L10.5387 3.21921C11.1439 2.92693 11.8561 2.92693 12.4613 3.21921L19 6.37746Z"
      class="app-fill-current"
    />
    <path
      d="M12 20V10.8519L20 7V14.7836C20 15.6199 19.5093 16.3844 18.7326 16.7584L12 20Z"
      class="app-fill-current"
    />
    <path
      d="M11 20V10.8519L3 7V14.7836C3 15.6199 3.49066 16.3844 4.26741 16.7584L11 20Z"
      class="app-fill-current"
    />
  </svg>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'icon-products',
});
</script>
