<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.29043 0.874702C8.11002 0.775164 7.89115 0.775164 7.71074 0.874702L1.91074 4.0747C1.88455 4.08915 1.85973 4.10539 1.83638 4.12322L8.00051 7.52412L14.1647 4.12317C14.1414 4.10536 14.1166 4.08914 14.0904 4.0747L8.29043 0.874702ZM14.4006 4.94479L8.41717 8.24598V15.0555L14.0904 11.9254C14.2818 11.8198 14.4006 11.6186 14.4006 11.4001V4.94479ZM7.58384 15.0554V8.24598L1.60059 4.94488V11.4001C1.60059 11.6186 1.7194 11.8198 1.91074 11.9254L7.58384 15.0554Z"
      class="app-fill-current"
    />
  </svg>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'icon-fba-inventory',
});
</script>
