import { Sales } from '@/store/classes/sales';
import { Units } from '@/store/classes/units';
import { Expenses } from '@/store/classes/expenses';
import { Profit } from '@/store/classes/profit';
import { Products } from '@/store/classes/products';
import { ProductVariations } from '@/store/classes/productVariations';

import {
  getPercent,
  allowedAdjustment,
  expenseNameMap,
} from '@/common/helpers/helpers.js';

import * as fetcher from '@/api/fetcher';
import { getCurrentRangeDatesDashboard } from '@/store/modules/dateranges';

const state = {
  /* * * * NEW STRUCTURE * * * */

  salesStatus: 'empty',
  unitsStatus: 'empty',
  expensesStatus: 'empty',
  profitStatus: 'empty',
  productsStatus: 'empty',

  sales: new Sales(),
  units: new Units(),
  expenses: new Expenses(),
  profit: new Profit(),
  products: new Products(),
  productVariations: new ProductVariations(),

  /* * * * * * * * * * * * * * */
};

const getters = {
  /* * * * NEW STRUCTURE * * * */

  salesStatus: state => state.salesStatus,
  unitsStatus: state => state.unitsStatus,
  expensesStatus: state => state.expensesStatus,
  profitStatus: state => state.profitStatus,
  productsStatus: state => state.productsStatus,

  sales: state => state.sales,
  units: state => state.units,
  expenses: state => state.expenses,
  profit: state => state.profit,
  products: state => state.products,
  productVariations: state => state.productVariations,

  /* * * * * * * * * * * * * * */

  dashboardFetching: state => {
    return (
      state.salesStatus === 'fetching' ||
      state.unitsStatus === 'fetching' ||
      state.expensesStatus === 'fetching' ||
      state.profitStatus === 'fetching' ||
      state.productsStatus === 'fetching'
    );
  },
};

const mutations = {
  /* * * * NEW STRUCTURE * * * */

  SET_SALES_STATUS(state, status) {
    state.salesStatus = status;
  },
  SET_UNITS_STATUS(state, status) {
    state.unitsStatus = status;
  },
  SET_EXPENSES_STATUS(state, status) {
    state.expensesStatus = status;
  },
  SET_PROFIT_STATUS(state, status) {
    state.profitStatus = status;
  },
  SET_PRODUCTS_STATUS(state, status) {
    state.productsStatus = status;
  },

  SET_SALES(state, { total, organic, ads }) {
    state.sales = new Sales(total, ads, organic);
  },
  SET_UNITS(state, { total, coupons, fullPrice }) {
    state.units = new Units(total, coupons, fullPrice);
  },
  SET_EXPENSES(state, { expenses }) {
    if (expenses instanceof Expenses) state.expenses = expenses;
    else state.expenses = new Expenses();
  },
  SET_PROFIT(state, { total, margin, roi }) {
    state.profit = new Profit(total, margin, roi);
  },

  CLEAR_SALES(state) {
    state.sales = new Sales();
  },
  CLEAR_UNITS(state) {
    state.units = new Units();
  },
  CLEAR_EXPENSES(state) {
    state.expenses = new Expenses();
  },
  CLEAR_PROFIT(state) {
    state.profit = new Profit();
  },
  CLEAR_PRODUCTS(state) {
    state.products = new Products();
    state.productVariations = new ProductVariations();
  },

  /* * * * * * * * * * * * * * */

  SET_DASHBOARD_TOTALS_STATUS(state, status) {
    /* NEW FEATURES */
    state.salesStatus = status;
    state.unitsStatus = status;
    state.expensesStatus = status;
    state.profitStatus = status;
    /* * * * * * * */
  },

  SET_DASHBOARD_PRODUCTS_STATUS(state, status) {
    /* NEW FEATURES */
    state.productsStatus = status;
    /* * * * * * * */
  },
};

const actions = {
  clear_dashboard_and_status({ dispatch }) {
    // dispatch('clear_dateranges');
    dispatch('clear_dashboard_totals');
    dispatch('clear_dashboard_products');
  },

  clear_dashboard_totals({ commit }) {
    commit('SET_DASHBOARD_TOTALS_STATUS', 'empty');

    /* NEW FEATURES */
    commit('CLEAR_SALES');
    commit('CLEAR_UNITS');
    commit('CLEAR_EXPENSES');
    commit('CLEAR_PROFIT');
    /* * * * * * * */
  },

  clear_dashboard_products({ commit }) {
    commit('SET_DASHBOARD_PRODUCTS_STATUS', 'empty');

    /* NEW FEATURES */
    commit('CLEAR_PRODUCTS');
    /* * * * * * * */
  },

  async created_dashboard_controller({ dispatch }) {
    dispatch('fetch_dashboard_totals_controller');
    dispatch('fetch_dashboard_products_controller');
  },

  async fetch_dashboard_totals_controller({ commit, dispatch }) {
    commit('SET_DASHBOARD_TOTALS_STATUS', 'fetching');
    dispatch('clear_dashboard_totals');
    await new Promise(r => setTimeout(r, 800));
    await dispatch('fetch_dashboard_totals');
    commit('SET_DASHBOARD_TOTALS_STATUS', 'fetched');
  },

  async fetch_dashboard_products_controller({ commit, dispatch }) {
    commit('SET_DASHBOARD_PRODUCTS_STATUS', 'fetching');
    dispatch('clear_dashboard_products');
    await dispatch('fetch_dashboard_products');
    commit('SET_DASHBOARD_PRODUCTS_STATUS', 'fetched');
  },

  async fetch_dashboard_totals({ dispatch, getters, commit }) {
    const { currentRangeType, currentRangeDates } = getters;
    const dashboardRange = getCurrentRangeDatesDashboard(
      currentRangeType,
      currentRangeDates
    );

    await Promise.all([
      (async () => {
        await dispatch('fetch_sales', {
          range: dashboardRange,
        });
      })(),
      (async () => {
        await dispatch('fetch_units', {
          range: dashboardRange,
        });
      })(),
      (async () => {
        await dispatch('fetch_expenses', {
          range: dashboardRange,
        });
      })(),
    ]);

    const profit = getters.sales.total - getters.expenses.total;
    commit('SET_PROFIT', {
      total: profit,
      margin: getPercent(profit, getters.sales.total),
      roi: getPercent(
        profit,
        getters.expenses.getExpense('Cost of Goods').total
      ),
    });
    getters.expenses.updatePercents(getters.sales.total);
  },

  async fetch_dashboard_products({ dispatch, getters }) {
    const { currentRangeType, currentRangeDates } = getters;
    const dashboardRange = getCurrentRangeDatesDashboard(
      currentRangeType,
      currentRangeDates
    );

    const category = getters.selectedCategory;
    await dispatch('fetch_productVariations', {
      range: dashboardRange,
      category: category === 'Parent ASIN' ? 'PARENT' : category,
    });
  },

  async fetch_sales({ commit }, { range }) {
    const data = await fetcher.fetch_sales({ range });

    commit('SET_SALES', {
      total: data.total,
      organic: data.organic,
      ads: data.ads,
    });
  },

  async fetch_units({ commit }, { range }) {
    const data = await fetcher.fetch_units({ range });

    commit('SET_UNITS', {
      total: data.total,
      coupons: data.coupons,
      fullPrice: data.fullPrice,
    });
  },

  async fetch_expenses({ commit }, { range }) {
    const data = await fetcher.fetch_expenses({ range });

    let expenses = new Expenses();
    for (const expenseName in data) {
      if (expenseName === 'total') continue;
      const _expenseName =
        expenseName === 'coupons'
          ? 'Discounts'
          : expenseNameMap[expenseName]
          ? expenseNameMap[expenseName]
          : expenseName;
      if (typeof data[expenseName] == 'number') {
        // is digit
        expenses.updateExpense(_expenseName, _expenseName, data[expenseName]);
      } else {
        // is dictionary
        for (const subexpenseName in data[expenseName]) {
          if (subexpenseName === 'total' || !allowedAdjustment(subexpenseName))
            continue;
          if (
            data[expenseName][subexpenseName] >= -0.5 &&
            data[expenseName][subexpenseName] <= 0.5 &&
            expenseName !== subexpenseName
          )
            continue;
          const _subexpenseName = expenseNameMap[subexpenseName]
            ? expenseNameMap[subexpenseName]
            : subexpenseName;
          expenses.updateExpense(
            _expenseName,
            _subexpenseName,
            data[expenseName][subexpenseName]
          );
        }
      }
    }

    expenses.finalPreparation();
    commit('SET_EXPENSES', {
      expenses,
    });
  },

  async fetch_productVariations({ getters }, { range, category }) {
    const data = await fetcher.fetch_productVariations({ range, category });
    for (const productVariation of data) {
      getters.productVariations.injectProductVariation(
        productVariation,
        category
      );
      for (const product of productVariation.products) {
        getters.products.injectProduct(product);
      }
    }
    getters.productVariations.finalPreparation();
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
