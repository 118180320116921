<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.62164 1.59998C2.50544 1.59998 1.60059 2.50483 1.60059 3.62103V12C1.60059 13.3255 2.6751 14.4 4.00059 14.4C5.32607 14.4 6.40059 13.3255 6.40059 12V3.62103C6.40059 2.50483 5.49573 1.59998 4.37953 1.59998H3.62164ZM11.622 7.77224L7.60036 11.7939V5.00569L8.22792 4.37813C9.01719 3.58886 10.2969 3.58885 11.0861 4.37812L11.622 4.91404C12.4113 5.70331 12.4113 6.98297 11.622 7.77224ZM6.69103 14.4001H12.3795C13.4957 14.4001 14.4006 13.4952 14.4006 12.379V11.6211C14.4006 10.505 13.4957 9.6001 12.3795 9.6001H11.491L6.69103 14.4001ZM4.00012 12.8C4.44195 12.8 4.80012 12.4418 4.80012 12C4.80012 11.5582 4.44195 11.2 4.00012 11.2C3.55829 11.2 3.20012 11.5582 3.20012 12C3.20012 12.4418 3.55829 12.8 4.00012 12.8Z"
      class="app-fill-current"
    />
  </svg>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'icon-custom-categories',
});
</script>
