import { CustomReportsApi } from '@/api/custom-reports';

const initialState = {
  data: [],
  error: null,
  status: null, // success, error, loading
};

const state = {
  ...initialState,
};

const getters = {
  reports: state => state.data,
};

const mutations = {
  loading(state) {
    state.status = 'loading';
    state.error = null;
    state.data = [];
  },
  success(state, { data }) {
    state.data = data;
    state.status = 'success';
  },
  failure(state, { error }) {
    state.error = error;
    state.status = 'error';
  },
  reset(state) {
    state.data = initialState.data;
    state.error = initialState.error;
    state.status = initialState.status;
  },
};

const actions = {
  clear_reports({ commit }) {
    commit('reset');
  },
  async load_reports({ commit }, { sellerId }) {
    await CustomReportsApi.getReports({ sellerId })
      .then(({ data }) => {
        commit('success', { data: mapReports(data) });
      })
      .catch(err => {
        commit('failure', { error: err.toString() });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

function mapReports(reports) {
  return reports.map(report => ({
    path: report.appNavigationLink,
    biLink: report.biLink,
    text: report.frontendSidebarText,
    width: report.width,
    height: report.height,
    iconName: report.iconName,
    variable: report.variable,
  }));
}
