<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.800293 3.39988C1.30169 3.02326 1.92493 2.8001 2.60029 2.8001H13.4003C14.0757 2.8001 14.6989 3.02327 15.2003 3.39988C15.2002 2.40587 14.3943 1.6001 13.4003 1.6001H2.60029C1.60625 1.6001 0.800412 2.40587 0.800293 3.39988Z"
      class="app-fill-current"
    />
    <path
      d="M0.800293 5.79988C1.30169 5.42326 1.92493 5.2001 2.60029 5.2001H13.4003C14.0757 5.2001 14.6989 5.42327 15.2003 5.79988C15.2002 4.80587 14.3943 4.0001 13.4003 4.0001H2.60029C1.60625 4.0001 0.800412 4.80587 0.800293 5.79988Z"
      class="app-fill-current"
    />
    <path
      d="M5.60029 6.4001C6.04212 6.4001 6.40029 6.75827 6.40029 7.2001C6.40029 8.08375 7.11664 8.8001 8.00029 8.8001C8.88395 8.8001 9.60029 8.08375 9.60029 7.2001C9.60029 6.75827 9.95847 6.4001 10.4003 6.4001H13.4003C14.3944 6.4001 15.2003 7.20598 15.2003 8.2001V12.6001C15.2003 13.5942 14.3944 14.4001 13.4003 14.4001H2.60029C1.60618 14.4001 0.800293 13.5942 0.800293 12.6001V8.2001C0.800293 7.20598 1.60618 6.4001 2.60029 6.4001H5.60029Z"
      class="app-fill-current"
    />
  </svg>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'icon-manual-expenses',
});
</script>
