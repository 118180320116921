import Vue from 'vue';

import { ChartPlugin } from '@syncfusion/ej2-vue-charts';
import { registerLicense } from '@syncfusion/ej2-base';

Vue.use(ChartPlugin);

registerLicense(
  'ORg4AjUWIQA/Gnt2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdiX35edXBXTmFYV0Y='
);
