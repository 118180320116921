import { getPrettyInteger, getPercent } from '@/common/helpers/helpers.js';
import { Expense } from '@/store/classes/expense';

const defaultExpenses = [
  'Adjustments',
  'Advertising',
  'Cost of Goods',
  'Discounts',
  'Fulfillment Fees',
  'Referral Fee',
  'Refunds',
  'Storage Fee',
  'Manual Exp.',
  'Other Fees',
];

class Expenses {
  constructor() {
    this.dict = {};
    this.list = [];

    this.total = 0;
    this.initDefaultExpenses();
  }

  get name() {
    return this._name;
  }
  get total() {
    return this._total;
  }
  get totalPretty() {
    if (this.total > -0.5 && this.total < 0.5) return 0;
    else return getPrettyInteger(this.total);
  }
  get list() {
    return this._list;
  }
  get dict() {
    return this._dict;
  }

  set name(val) {
    this._name = val;
  }
  set total(val) {
    this._total = val;
  }
  set list(val) {
    this._list = val;
  }
  set dict(val) {
    this._dict = val;
  }

  initDefaultExpenses() {
    defaultExpenses.forEach(name => {
      this.createExpense(name);
    });
  }
  createExpense(name) {
    this.dict[name] = new Expense(name);
  }

  getExpense(name) {
    return this.dict[name] ? this.dict[name] : new Expense();
  }

  updateExpense(expenseName, subexpenseName, total) {
    this.getExpense(expenseName).addSubexpense(subexpenseName, total);
    this.total += total;
  }

  finalPreparation(salesTotal = this.total) {
    this.updatePercents(salesTotal);
    this.collectionDictToList();
    this.sort();

    this.list.forEach(expense => {
      expense.finalPreparation();
    });
  }

  updatePercents(salesTotal) {
    for (let expenseName in this.dict) {
      let expense = this.getExpense(expenseName);
      expense.percent = getPercent(expense.total, salesTotal);
    }
  }

  collectionDictToList() {
    this.list = [];
    for (let expenseName in this.dict) {
      this.list.push(this.dict[expenseName]);
    }
  }

  sort() {
    this.list = this.list.sort((a, b) => {
      return a.total > b.total ? -1 : 1;
    });
  }
}

export { Expenses };
