import store from '@/store';
import { $api } from '@/main';
import { getCurrentRangeDatesDashboard } from '@/store/modules/dateranges';

async function fetch_user_details() {
  return await $api.get('user/details', 'user_details');
}

async function fetch_subscriptions() {
  return await $api.get('payments/get-subscription', 'subscriptions');
}

async function fetch_sellers() {
  return await $api.get('amazon-selling-partners', 'sellers').then(sellers => {
    return sellers
      .map(seller => ({
        ...seller,
        name: seller.name.trim(),
      }))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
  });
}

async function fetch_seller_details({ amazonSellingPartnerId }) {
  return await $api.get(
    'amazon-selling-partners/details/' + amazonSellingPartnerId,
    'seller_details'
  );
}

async function fetch_has_missing_cogs({ amazonSellingPartnerId }) {
  const data = {
    sellerId: amazonSellingPartnerId,
    marketplaceId: store.getters.currentMarketplaceId,
  };
  const params = new URLSearchParams(data);
  const path = `has-missing-cogs?${params}`;

  return await $api.get(path, 'has_missing_cogs');
}

async function fetch_custom_categories() {
  const data = {
    sellerId: store.getters.currentSellerId,
    marketplaceId: store.getters.currentMarketplaceId,
  };
  const params = new URLSearchParams(data);
  const path = `custom-categories?${params}`;

  return await $api.get(path, 'custom_categories');
}

async function fetch_custom_category_products_list({ category }) {
  const data = {
    sellerId: store.getters.currentSellerId,
    marketplaceId: store.getters.currentMarketplaceId,
    category: category,
  };
  const params = new URLSearchParams(data);
  const path = `custom-category/list?${params}`;

  return await $api.get(path, 'custom_category_products_list');
}

async function fetch_custom_category_products_csv({ category }) {
  const data = {
    sellerId: store.getters.currentSellerId,
    marketplaceId: store.getters.currentMarketplaceId,
    category: category,
  };
  const params = new URLSearchParams(data);
  const path = `custom-category/csv/download?${params}`;

  return await $api.get(path, 'custom_category_products_csv');
}

async function fetch_products_and_cogs_csv() {
  const data = {
    sellerId: store.getters.currentSellerId,
    currencyCode: store.getters.currentCurrencyCode,
  };
  const params = new URLSearchParams(data);
  const path = `cogs/csv/download?${params}`;

  return await $api.get(path, 'products_and_cogs_csv');
}

async function fetch_products_and_cogs() {
  const data = {
    sellerId: store.getters.currentSellerId,
    currencyCode: store.getters.currentCurrencyCode,
  };
  const params = new URLSearchParams(data);
  const path = `cogs/list?${params}`;

  return await $api.get(path, 'products_and_cogs');
}

async function fetch_manual_expenses() {
  const data = {
    marketplaceId: store.getters.currentMarketplaceId,
    sellingPartnerId: store.getters.currentSellerId,
  };
  const params = new URLSearchParams(data);
  const path = `getAmazonManualExpenses?${params}`;

  return await $api.get(path, 'manual_expenses');
}

async function fetch_sales({ range }) {
  const data = {
    marketplaceId: store.getters.currentMarketplaceId,
    sellerId: store.getters.currentSellerId,
    startDate: range.start,
    endDate: range.end,
  };
  const params = new URLSearchParams(data);
  const path = `amazonSalesTotal?${params}`;

  return await $api.get(path, 'sales');
}

async function fetch_units({ range }) {
  const data = {
    marketplaceId: store.getters.currentMarketplaceId,
    sellerId: store.getters.currentSellerId,
    startDate: range.start,
    endDate: range.end,
  };
  const params = new URLSearchParams(data);
  const path = `amazonUnitsTotal?${params}`;

  return await $api.get(path, 'units');
}

async function fetch_expenses({ range }) {
  const data = {
    marketplaceId: store.getters.currentMarketplaceId,
    sellerId: store.getters.currentSellerId,
    startDate: range.start,
    endDate: range.end,
  };
  const params = new URLSearchParams(data);
  const path = `amazonExpensesTotal?${params}`;

  return await $api.get(path, 'expenses');
}

async function fetch_productVariations({ range, category }) {
  const data = {
    marketplaceId: store.getters.currentMarketplaceId,
    sellerId: store.getters.currentSellerId,
    startDate: range.start,
    endDate: range.end,
    category: category,
  };
  const params = new URLSearchParams(data);
  const path = `amazonProductVariations?${params}`;

  return await $api.get(path, 'product_variations');
}

async function fetch_sales_profit_chart({ skus, includeUnitemised }) {
  const { currentRangeType, currentRangeDates } = store.getters;
  const { start, end } = getCurrentRangeDatesDashboard(
    currentRangeType,
    currentRangeDates
  );

  const payload = {
    sellerId: store.getters.currentSellerId,
    marketplaceId: store.getters.currentMarketplaceId,
    skus: skus.length ? skus : undefined,
    start,
    end,
    includeUnitemised,
  };
  const path = `daily-profit`;

  return await $api.make_post(path, 'get_sales_profit_chart', payload);
}

async function fetch_product_details() {
  const data = {
    sellerId: store.getters.currentSellerId,
    marketplaceId: store.getters.currentMarketplaceId,
  };
  const params = new URLSearchParams(data);
  const path = `product-details?${params}`;

  return await $api.get(path, 'product_details');
}

export {
  fetch_user_details,
  fetch_subscriptions,
  fetch_sellers,
  fetch_seller_details,
  fetch_has_missing_cogs,
  fetch_custom_categories,
  fetch_custom_category_products_list,
  fetch_custom_category_products_csv,
  fetch_products_and_cogs_csv,
  fetch_products_and_cogs,
  fetch_manual_expenses,
  fetch_sales,
  fetch_units,
  fetch_expenses,
  fetch_productVariations,
  fetch_sales_profit_chart,
  fetch_product_details,
};
