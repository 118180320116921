import { Timer } from '@/common/helpers/timer.js';

export const alertType = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
};

const state = {
  /* * * * ALERTS * * * */
  alerts: [],
  alertCounter: 0,
};

const getters = {
  /* * * * ALERTS * * * */
  alerts: state => state.alerts,
  alertCounter: state => state.alertCounter,
};

const mutations = {
  /* * * * ALERTS * * * */
  ADD_ALERT(state, alert) {
    state.alerts = [...state.alerts, alert];
    state.alertCounter = (state.alertCounter + 1) % 10000;
  },

  PAUSE_ALERT(state, alertId) {
    state.alerts.find(alert => alert.id === alertId).timer.pause();
  },

  UNPAUSE_ALERT(state, alertId) {
    state.alerts.find(alert => alert.id === alertId).timer.start();
  },

  CLEAR_ALERTS(state) {
    state.alerts = [];
  },

  REMOVE_ALERT(state, alertId) {
    state.alerts = [...state.alerts.filter(alert => alert.id !== alertId)];
  },
};

const actions = {
  /* * * * ALERTS * * * */
  addAlert({ commit, getters }, alert) {
    alert = {
      ...alert,
      id: getters.alertCounter,
      timer: new Timer(() => commit('REMOVE_ALERT', alert.id), alert.delay),
    };
    commit('ADD_ALERT', alert);
  },

  logAlertInfo({ dispatch }, payload) {
    dispatch('addAlert', {
      type: alertType.INFO,
      msg: payload.msg,
      delay: payload.delay ? payload.delay : 5000,
    });
  },
  logAlertSuccess({ dispatch }, payload) {
    dispatch('addAlert', {
      type: alertType.SUCCESS,
      msg: payload.msg,
      delay: payload.delay ? payload.delay : 5000,
    });
  },
  logAlertError({ dispatch }, payload) {
    dispatch('addAlert', {
      type: alertType.ERROR,
      msg: payload.msg,
      delay: payload.delay ? payload.delay : 5000,
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
