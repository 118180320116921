<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4013_208)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.67167 1.44303C6.74646 1.0691 7.07479 0.799927 7.45614 0.799927H8.54445C8.9258 0.799927 9.25413 1.06909 9.32891 1.44303L9.56457 2.62133C9.96208 2.73674 10.3413 2.89502 10.697 3.09083L11.6972 2.42404C12.0145 2.21251 12.437 2.25434 12.7067 2.52399L13.4762 3.29355C13.7459 3.5632 13.7877 3.98569 13.5762 4.30299L12.9094 5.30319C13.1052 5.65888 13.2635 6.03815 13.3789 6.43565L14.5572 6.67131C14.9311 6.74609 15.2003 7.07443 15.2003 7.45577V8.54408C15.2003 8.92543 14.9311 9.25376 14.5572 9.32855L13.3789 9.56421C13.2635 9.96168 13.1052 10.3409 12.9094 10.6966L13.5763 11.6969C13.7878 12.0142 13.746 12.4367 13.4763 12.7063L12.7068 13.4759C12.4371 13.7455 12.0146 13.7874 11.6973 13.5758L10.6971 12.909C10.3414 13.1048 9.96209 13.2631 9.56457 13.3785L9.32892 14.5568C9.25413 14.9308 8.9258 15.1999 8.54445 15.1999H7.45614C7.07479 15.1999 6.74646 14.9308 6.67167 14.5568L6.43601 13.3785C6.03851 13.2631 5.65925 13.1048 5.30356 12.909L4.30335 13.5758C3.98605 13.7874 3.56356 13.7455 3.2939 13.4759L2.52435 12.7063C2.2547 12.4367 2.21286 12.0142 2.42439 11.6969L3.0912 10.6967C2.89538 10.341 2.7371 9.96171 2.6217 9.56421L1.4434 9.32855C1.06946 9.25376 0.800293 8.92543 0.800293 8.54408L0.800293 7.45577C0.800293 7.07443 1.06946 6.74609 1.4434 6.67131L2.6217 6.43565C2.73711 6.03812 2.8954 5.65883 3.09124 5.30311L2.42448 4.30298C2.21295 3.98569 2.25479 3.56319 2.52444 3.29354L3.29399 2.52398C3.56364 2.25433 3.98614 2.2125 4.30344 2.42403L5.3036 3.0908C5.65928 2.895 6.03853 2.73673 6.43601 2.62133L6.67167 1.44303ZM10.4003 7.99993C10.4003 9.32541 9.32578 10.3999 8.00029 10.3999C6.67481 10.3999 5.60029 9.32541 5.60029 7.99993C5.60029 6.67444 6.67481 5.59993 8.00029 5.59993C9.32578 5.59993 10.4003 6.67444 10.4003 7.99993Z"
        class="app-fill-current"
      />
    </g>
    <defs>
      <clipPath id="clip0_4013_208">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'icon-settings',
});
</script>
