import { getPrettyInteger } from '@/common/helpers/helpers.js';

import { Subexpenses } from '@/store/classes/subexpenses';

class Expense {
  constructor(name, total = 0) {
    this.name = name;
    this.total = total;
    this.percent = 0;

    this.subexpenses = new Subexpenses();
  }

  get name() {
    return this._name;
  }
  get total() {
    return this._total;
  }
  get totalPretty() {
    return getPrettyInteger(this.total);
  }
  get percent() {
    return this._percent;
  }
  get subexpenses() {
    return this._subexpenses;
  }

  set name(val) {
    this._name = val;
  }
  set total(val) {
    this._total = val;
  }
  set percent(val) {
    this._percent = val;
  }
  set subexpenses(val) {
    if (val instanceof Subexpenses) {
      this._subexpenses = val;
    }
  }

  addSubexpense(name, total = 0) {
    this.subexpenses.insert(name, total);
    this.total += total;
  }

  finalPreparation() {
    this.subexpenses.finalPreparation();
  }
}

export { Expense };
