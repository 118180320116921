<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.75 1.00006H6C7.38071 1.00006 8.5 2.11935 8.5 3.50006V4.75006C8.5 5.44042 9.05964 6.00006 9.75 6.00006H11C12.3807 6.00006 13.5 7.11935 13.5 8.50006V13.7501C13.5 14.4404 12.9404 15.0001 12.25 15.0001H3.75C3.05964 15.0001 2.5 14.4404 2.5 13.7501V2.25006C2.5 1.5597 3.05964 1.00006 3.75 1.00006ZM8.5 8.00006C8.5 7.72392 8.27614 7.50006 8 7.50006C7.72386 7.50006 7.5 7.72392 7.5 8.00006V9.50006H6C5.72386 9.50006 5.5 9.72392 5.5 10.0001C5.5 10.2762 5.72386 10.5001 6 10.5001H7.5V12.0001C7.5 12.2762 7.72386 12.5001 8 12.5001C8.27614 12.5001 8.5 12.2762 8.5 12.0001V10.5001H10C10.2761 10.5001 10.5 10.2762 10.5 10.0001C10.5 9.72392 10.2761 9.50006 10 9.50006H8.5V8.00006Z"
      class="app-fill-current"
    />
    <path
      d="M9.5 3.50006C9.5 2.62469 9.17864 1.82438 8.64746 1.21066C10.9134 1.80258 12.6975 3.58665 13.2894 5.8526C12.6757 5.32142 11.8754 5.00006 11 5.00006H9.75C9.61193 5.00006 9.5 4.88813 9.5 4.75006V3.50006Z"
      class="app-fill-current"
    />
  </svg>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'icon-custom-report',
});
</script>
