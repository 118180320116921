import { $api } from '@/main';

export const CustomReportsApi = {
  getReports({ sellerId }) {
    const params = {
      sellerId,
    };

    return $api.make_get('bi/report', 'custom-reports', { params });
  },
};
