<template>
  <div class="loader-overlay" v-if="getLoaderState">
    <svg
      class="loader-overlay__circle"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'LoaderOverlay',
  data() {
    return {
      state: false,
    };
  },
  computed: {
    getLoaderState() {
      return this.$store.getters.loading;
    },
  },
};
</script>
<style lang="scss" scoped>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 999999; // TODO: z-index
  display: flex;
  align-items: center;
  justify-content: center;
  &__circle {
    width: 100px;
    height: 100px;
    path {
      fill: var(--main-purple-color);
    }
  }
}
</style>
