<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.39941 3.4001C2.39941 2.40599 3.2053 1.6001 4.19941 1.6001H8.59941C9.59353 1.6001 10.3994 2.40599 10.3994 3.4001V5.0001C10.3994 5.33147 10.1308 5.6001 9.79941 5.6001C9.46804 5.6001 9.19941 5.33147 9.19941 5.0001V3.4001C9.19941 3.06873 8.93079 2.8001 8.59941 2.8001H4.19941C3.86804 2.8001 3.59941 3.06873 3.59941 3.4001V12.6001C3.59941 12.9315 3.86804 13.2001 4.19941 13.2001H8.59941C8.93079 13.2001 9.19941 12.9315 9.19941 12.6001V11.0001C9.19941 10.6687 9.46804 10.4001 9.79941 10.4001C10.1308 10.4001 10.3994 10.6687 10.3994 11.0001V12.6001C10.3994 13.5942 9.59353 14.4001 8.59941 14.4001H4.19941C3.2053 14.4001 2.39941 13.5942 2.39941 12.6001V3.4001Z"
      class="app-fill-current"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.79941 8.0001C4.79941 7.66873 5.06804 7.4001 5.39941 7.4001H13.0358L12.198 6.64607C11.9517 6.4244 11.9318 6.04502 12.1534 5.79872C12.3751 5.55241 12.7545 5.53245 13.0008 5.75412L15.0008 7.55412C15.1272 7.66791 15.1994 7.83001 15.1994 8.0001C15.1994 8.17019 15.1272 8.33229 15.0008 8.44607L13.0008 10.2461C12.7545 10.4677 12.3751 10.4478 12.1534 10.2015C11.9318 9.95517 11.9517 9.5758 12.198 9.35412L13.0358 8.6001H5.39941C5.06804 8.6001 4.79941 8.33147 4.79941 8.0001Z"
      class="app-fill-current"
    />
  </svg>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'icon-sign-out',
});
</script>
