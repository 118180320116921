<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1364 3.07591C9.58903 3.87337 7.89124 4.41944 6.09673 4.66039C5.4113 4.75242 4.71151 4.79997 4.00029 4.79997C2.23298 4.79997 0.800293 6.23266 0.800293 7.99997C0.800293 9.63071 2.02011 10.9765 3.59707 11.1748C3.83055 12.085 4.16812 12.9815 4.61393 13.8478C4.93117 14.4644 5.68751 14.6469 6.2544 14.3196L6.94722 13.9196C7.5284 13.584 7.69963 12.8627 7.41811 12.3025C7.28524 12.0381 7.16577 11.7703 7.05944 11.4997C8.49972 11.786 9.86855 12.2706 11.1364 12.924C11.6954 11.3878 12.0003 9.72947 12.0003 7.99996C12.0003 6.27046 11.6954 4.61217 11.1364 3.07591Z"
      class="app-fill-current"
    />
    <path
      d="M12.1945 2.47721C12.8445 4.194 13.2003 6.05543 13.2003 7.99996C13.2003 9.9445 12.8445 11.8059 12.1945 13.5227C12.1945 13.5228 12.1944 13.5227 12.1945 13.5227L12.1711 13.5845C12.0525 13.8939 12.2071 14.2409 12.5165 14.3596C12.8259 14.4782 13.1729 14.3236 13.2916 14.0142C13.3604 13.8346 13.4263 13.6536 13.4891 13.4711C13.9324 12.1834 14.2236 10.8253 14.3412 9.41826C14.8519 9.15103 15.2003 8.61655 15.2003 7.99997C15.2003 7.38339 14.8519 6.84892 14.3412 6.58169C14.2236 5.1747 13.9324 3.81652 13.4891 2.52887C13.4263 2.34637 13.3604 2.1653 13.2916 1.98571C13.1729 1.67632 12.8259 1.52169 12.5165 1.64035C12.2071 1.75901 12.0525 2.10602 12.1711 2.41542L12.1945 2.47721C12.1945 2.47724 12.1945 2.47718 12.1945 2.47721Z"
      class="app-fill-current"
    />
  </svg>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'icon-advertising-insights',
});
</script>
