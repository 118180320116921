import axios from 'axios';
import { axiosInstance } from '@/plugins';

export const CANCELLED_MESSAGE = 'Operation canceled due to new request.';

export default class Api {
  cancelTokens = new Map();

  async get(url, key, config = {}) {
    const cancelToken = await this.getCancelToken(key);

    const { data } = await axiosInstance.get(url, {
      ...config,
      cancelToken,
    });

    return data;
  }

  async make_get(url, key, config = {}) {
    const cancelToken = await this.getCancelToken(key);

    return await axiosInstance.get(url, {
      ...config,
      cancelToken,
    });
  }

  async make_post(url, key, payload, config) {
    const cancelToken = await this.getCancelToken(key);

    return await axiosInstance.post(url, payload, {
      ...config,
      cancelToken,
    });
  }

  async post(url, data, config) {
    return axiosInstance.post(url, data, config);
  }

  async delete(url) {
    return axiosInstance.delete(url);
  }

  async getCancelToken(key) {
    if (!key) {
      return undefined;
    }

    const cancelToken = this.cancelTokens.has(key);
    if (cancelToken) {
      await this.cancelTokens.get(key).cancel(CANCELLED_MESSAGE);
    }

    this.cancelTokens.set(key, axios.CancelToken.source());

    return this.cancelTokens.get(key).token;
  }
}
