<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.60005 8.65407V10.7459C8.94536 10.6821 9.25972 10.5636 9.51018 10.3999C9.89594 10.1476 10.0001 9.8816 10.0001 9.69998C10.0001 9.51835 9.89594 9.2523 9.51018 9.00008C9.25972 8.83631 8.94536 8.71785 8.60005 8.65407Z"
      class="app-fill-current"
    />
    <path
      d="M6.66378 6.89555C6.7068 6.94032 6.75581 6.9844 6.81133 7.02731C6.97768 7.15585 7.17894 7.25419 7.4001 7.3175V5.28249C7.34046 5.29957 7.28227 5.31919 7.22582 5.34126C7.18986 5.35532 7.15462 5.37037 7.12016 5.38639C7.00806 5.4385 6.90425 5.50088 6.81133 5.57269C6.50936 5.80603 6.4001 6.074 6.4001 6.3C6.4001 6.44707 6.44637 6.61192 6.56192 6.77344C6.59128 6.81447 6.6251 6.85529 6.66378 6.89555Z"
      class="app-fill-current"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.4001 7.99998C14.4001 11.5346 11.5347 14.4 8.0001 14.4C4.46548 14.4 1.6001 11.5346 1.6001 7.99998C1.6001 4.46535 4.46548 1.59998 8.0001 1.59998C11.5347 1.59998 14.4001 4.46535 14.4001 7.99998ZM8.00005 3.19998C8.33142 3.19998 8.60005 3.4686 8.60005 3.79998V4.05265C9.0739 4.13682 9.53466 4.32339 9.92255 4.62312C10.2635 4.8866 10.5174 5.21466 10.6627 5.58315C10.7842 5.89143 10.6328 6.23986 10.3245 6.36137C10.0162 6.48288 9.66777 6.33147 9.54626 6.02318C9.48804 5.87547 9.37609 5.71737 9.18881 5.57267C9.02247 5.44413 8.8212 5.34578 8.60005 5.28247V7.43953C9.15851 7.51384 9.70625 7.69453 10.1669 7.99571C10.7965 8.40738 11.2001 9.01146 11.2001 9.69998C11.2001 10.3885 10.7965 10.9926 10.1669 11.4042C9.70625 11.7054 9.15851 11.8861 8.60005 11.9604V12.2C8.60005 12.5313 8.33142 12.8 8.00005 12.8C7.66868 12.8 7.40005 12.5313 7.40005 12.2V11.9604C6.84159 11.8861 6.29386 11.7054 5.83323 11.4042C5.4472 11.1518 5.14705 10.8301 4.9708 10.4537C4.83026 10.1536 4.9596 9.7964 5.25969 9.65586C5.55978 9.51532 5.91698 9.64466 6.05752 9.94475C6.12216 10.0828 6.25363 10.2454 6.48993 10.3999C6.74039 10.5636 7.05474 10.6821 7.40005 10.7459V8.5473C6.9262 8.46313 6.46543 8.27656 6.07755 7.97683C5.52037 7.54628 5.20005 6.94411 5.20005 6.29997C5.20005 5.65584 5.52037 5.05367 6.07755 4.62312C6.46543 4.32339 6.9262 4.13682 7.40005 4.05265V3.79998C7.40005 3.4686 7.66868 3.19998 8.00005 3.19998Z"
      class="app-fill-current"
    />
  </svg>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'icon-profit-analysis',
});
</script>
