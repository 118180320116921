import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes';
import { authGuard } from '@/router/guards/authGuard';

Vue.use(VueRouter);

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => authGuard(to, from, next));

export default router;
