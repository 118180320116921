<template>
  <v-container class="main-footer py-12">
    <div class="main-footer__icons mb-5 mb-md-0">
      <a href="https://www.linkedin.com/company/myrealprofit/" target="_blank">
        <v-icon color="var(--gray-400)" size="28">mdi-linkedin</v-icon>
      </a>
      <a href="https://www.facebook.com/myrealprofit" target="_blank">
        <v-icon color="var(--gray-400)" size="28">mdi-facebook</v-icon>
      </a>
    </div>

    <nav class="main-footer__links mb-5 mb-md-0">
      <a href="https://myrealprofit.com/privacy-policy">
        Privacy Policy
      </a>

      <a href="https://myrealprofit.com/terms-and-conditions">
        Terms and Conditions
      </a>
    </nav>

    <p class="main-footer__copy mb-0">
      &copy; 2023 My Real Profit Inc.
      <span class="d-inline-block">All rights reserved.</span>
    </p>
  </v-container>
</template>

<script>
export default {
  name: 'MainFooter',
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.main-footer {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: flex;
    align-items: center;
  }
  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      order: 2;
      flex-direction: row;
    }

    a {
      color: var(--gray-600);
      font: 500 1rem Inter;
      margin: 10px 0;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        margin: 0 0 0 1rem;
      }
    }
  }
  &__copy {
    color: var(--gray-500);
    font: 300 1rem Inter;
    text-align: center;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      order: 1;
    }
  }
  &__icons {
    text-align: center;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      order: 3;
    }

    > * {
      margin: 0 10px;
    }
  }
}
</style>
