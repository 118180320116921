<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4013_205)">
      <path
        d="M0.800293 1.40005C0.800293 1.06868 1.06892 0.800049 1.40029 0.800049H2.70306C3.40234 0.800049 3.99438 1.31604 4.08993 2.00876L4.14393 2.40024C7.80758 2.41015 11.3828 2.79903 14.8321 3.52994C15.1519 3.5977 15.3586 3.90895 15.297 4.22997C14.9719 5.92443 14.5377 7.58017 14.0019 9.18955C13.9203 9.43468 13.691 9.60005 13.4326 9.60005H4.8003C4.70883 9.60005 4.6191 9.60615 4.53144 9.6179C3.82865 9.71205 3.24103 10.1723 2.96666 10.8H13.8003C14.1317 10.8 14.4003 11.0687 14.4003 11.4C14.4003 11.7314 14.1317 12 13.8003 12H2.20787C2.04084 12 1.88136 11.9304 1.76781 11.8079C1.65427 11.6854 1.59693 11.5211 1.6096 11.3545C1.70882 10.0503 2.5885 8.96554 3.78292 8.5653L2.90119 2.17272C2.88754 2.07376 2.80296 2.00005 2.70306 2.00005H1.40029C1.06892 2.00005 0.800293 1.73142 0.800293 1.40005Z"
        class="app-fill-current"
      />
      <path
        d="M4.80029 14.0001C4.80029 14.6628 4.26304 15.2001 3.60029 15.2001C2.93755 15.2001 2.40029 14.6628 2.40029 14.0001C2.40029 13.3373 2.93755 12.8 3.60029 12.8C4.26304 12.8 4.80029 13.3373 4.80029 14.0001Z"
        class="app-fill-current"
      />
      <path
        d="M12.4003 15.2001C13.063 15.2001 13.6003 14.6628 13.6003 14.0001C13.6003 13.3373 13.063 12.8 12.4003 12.8C11.7376 12.8 11.2003 13.3373 11.2003 14.0001C11.2003 14.6628 11.7376 15.2001 12.4003 15.2001Z"
        class="app-fill-current"
      />
    </g>
    <defs>
      <clipPath id="clip0_4013_205">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'icon-products-and-cogs',
});
</script>
