import { DateTime } from 'luxon';

export function mapRangeTypeToDatesInLAZone(rangeType, customDate = null) {
  const today = DateTime.local().setZone('America/Los_Angeles');
  let dates;
  switch (rangeType) {
    case 'today':
      dates = {
        start: today.startOf('day'),
        end: today.endOf('day'),
      };
      break;
    case 'yesterday':
      dates = {
        start: today.minus({ days: 1 }).startOf('day'),
        end: today.minus({ days: 1 }).endOf('day'),
      };
      break;
    case 'this_month':
      dates = {
        start: today.startOf('month'),
        end: today.endOf('month'),
      };
      break;
    case 'last_month':
      dates = {
        start: today.minus({ month: 1 }).startOf('month'),
        end: today.minus({ month: 1 }).endOf('month'),
      };
      break;
    case 'last_7_days':
      dates = {
        start: today.minus({ days: 7 }).startOf('day'),
        end: today.minus({ days: 1 }).endOf('day'),
      };
      break;
    case 'last_30_days':
      dates = {
        start: today.minus({ days: 30 }).startOf('day'),
        end: today.minus({ days: 1 }).endOf('day'),
      };
      break;
    case 'this_quarter':
      dates = {
        start: today.startOf('quarter'),
        end: today.endOf('quarter'),
      };
      break;
    case 'last_quarter':
      dates = {
        start: today.minus({ quarter: 1 }).startOf('quarter'),
        end: today.minus({ quarter: 1 }).endOf('quarter'),
      };
      break;
    case 'custom':
      dates = {
        start: customDate.start,
        end: customDate.end,
      };
      break;
    default:
      dates = {
        start: today.startOf('day'),
        end: today.endOf('day'),
      };
      break;
  }
  return {
    start: dates.start.toUTC().toISO(),
    end: dates.end.toUTC().toISO(),
  };
}
