<template>
  <div class="alerts-container">
    <div
      class="alerts-list position-fixed flex-column align-center"
      v-if="alerts.length > 0"
    >
      <div
        class="alerts-list-alert flex-row align-center justify-space-between"
        v-bind:class="{
          'alerts-list-alert__error': alert.type === alertType.ERROR,
          'alerts-list-alert__success': alert.type === alertType.SUCCESS,
          'alerts-list-alert__info': alert.type === alertType.INFO,
        }"
        v-for="alert in alerts"
        v-bind:key="alert.id"
        @mouseenter="pauseAlert(alert)"
        @mouseleave="unpauseAlert(alert)"
      >
        <div class="flex-row">
          <img
            :src="alertPrefix(alert.type).src"
            v-bind:alt="alertPrefix(alert.type).alt"
          />
          <horizontal-space-divider
            :config="{ width: '12px' }"
          ></horizontal-space-divider>
          <inter
            :content="{
              text: alert.msg,
            }"
            :config="{
              weight: 'Medium',
              color: 'var(--gray-500)',
              'font-size': '14px',
              'line-height': '22px',
            }"
          ></inter>
        </div>

        <img
          class="alerts-list-alert-suffix"
          @click="removeAlert(alert)"
          :src="alertSuffix(alert.type).src"
          alt="Remove Icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { alertType } from '@/store/modules/alert';
import Inter from '@/common/components/Texts/Inter.vue';
import HorizontalSpaceDivider from '@/common/components/HorizontalSpaceDivider.vue';

export default {
  name: 'Alert',
  components: {
    Inter,
    HorizontalSpaceDivider,
  },
  data() {
    return {
      alertType: alertType,
    };
  },
  computed: {
    alerts() {
      return this.$store.getters.alerts;
    },
  },
  methods: {
    alertPrefix(alertType) {
      switch (alertType) {
        case this.alertType.ERROR:
          return {
            src: require('@/assets/svg/alerts/alert-error.svg'),
            alt: 'Error Icon',
          };
        case this.alertType.SUCCESS:
          return {
            src: require('@/assets/svg/alerts/alert-success.svg'),
            alt: 'Success Icon',
          };
        case this.alertType.INFO:
          return {
            src: require('@/assets/svg/alerts/alert-info.svg'),
            alt: 'Info Icon',
          };
        default:
          return {
            src: require('@/assets/svg/alerts/alert-info.svg'),
            alt: 'Info Icon',
          };
      }
    },
    alertSuffix(alertType) {
      switch (alertType) {
        case this.alertType.ERROR:
          return {
            src: require('@/assets/svg/alerts/remove-cross-error.svg'),
          };
        case this.alertType.SUCCESS:
          return {
            src: require('@/assets/svg/alerts/remove-cross-success.svg'),
          };
        case this.alertType.INFO:
          return {
            src: require('@/assets/svg/alerts/remove-cross-info.svg'),
          };
        default:
          return {
            src: require('@/assets/svg/alerts/remove-cross-info.svg'),
          };
      }
    },

    pauseAlert(alert) {
      this.$store.commit('PAUSE_ALERT', alert.id);
    },

    unpauseAlert(alert) {
      this.$store.commit('UNPAUSE_ALERT', alert.id);
    },

    removeAlert(alert) {
      this.$store.commit('REMOVE_ALERT', alert.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.alerts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.alerts-list {
  z-index: 9999991;
  top: 54px;
  width: min(95%, 660px);

  &-alert {
    margin-top: 10px;
    padding: 16px;
    border-radius: 6px;
    text-align: left;
    width: 100%;

    &-suffix {
      cursor: pointer;
    }
  }

  &-alert__error {
    background-color: var(--red-50);
  }
  &-alert__error .content {
    color: var(--red-800) !important;
  }

  &-alert__success {
    background-color: var(--green-50);
  }
  &-alert__success .content {
    color: var(--green-800) !important;
  }

  &-alert__info {
    background-color: #ecebfc;
  }
  &-alert__info .content {
    color: #443ce5 !important;
  }
}
</style>
