import { expenseNameMap, getPercent } from '@/common/helpers/helpers.js';

import { Sales } from '@/store/classes/sales';
import { Units } from '@/store/classes/units';
import { Expenses } from '@/store/classes/expenses';
import { Kpis } from '@/store/classes/kpis';
import { Profit } from '@/store/classes/profit';

class Product {
  constructor(
    sku,
    asin = 'Undefined',
    name = 'Undefined',
    variation = 'Undefined',
    img = '../png/undefined.png',
    averagePrice = 0.0
  ) {
    this.name = name;
    this.img = img;

    this.sku = sku;
    this.asin = asin;
    this.variation = variation;
    this.averagePrice = averagePrice;

    this.sales = new Sales();
    this.units = new Units();
    this.expenses = new Expenses();
    this.kpis = new Kpis();
  }

  /* * * * * * * GETTERS * * * * * * * * */
  get name() {
    return this._name;
  }
  get img() {
    return this._img;
  }

  get sku() {
    return this._sku;
  }
  get asin() {
    return this._asin;
  }
  get variation() {
    return this._variation;
  }
  get averagePrice() {
    return this._averagePrice;
  }
  get averagePricePretty() {
    return this.averagePrice.toFixed(2);
  }

  get sales() {
    return this._sales;
  }
  get units() {
    return this._units;
  }
  get expenses() {
    return this._expenses;
  }
  get kpis() {
    return this._kpis;
  }
  get profit() {
    const profit = this.sales.total - this.expenses.total;
    const margin = getPercent(profit, this.sales.total);
    const roi = getPercent(
      profit,
      this.expenses.getExpense('Cost of Goods').total
    );
    return new Profit(profit, margin, roi);
  }
  get refundRate() {
    return getPercent(this.kpis.refundsQuantity, this.units.total);
  }
  /* * * * * * * * * * * * * * * * * * * */

  /* * * * * * * SETTERS * * * * * * * * */
  set name(val) {
    this._name = val;
  }
  set img(val) {
    this._img = val;
  }

  set sku(val) {
    this._sku = val;
  }
  set asin(val) {
    this._asin = val;
  }
  set variation(val) {
    this._variation = val;
  }
  set averagePrice(val) {
    this._averagePrice = val;
  }

  set sales(val) {
    if (val instanceof Sales) {
      this._sales = val;
    }
  }

  set units(val) {
    if (val instanceof Units) {
      this._units = val;
    }
  }

  set expenses(val) {
    if (val instanceof Expenses) {
      this._expenses = val;
    }
  }

  set kpis(val) {
    if (val instanceof Kpis) {
      this._kpis = val;
    }
  }
  /* * * * * * * * * * * * * * * * * * * */

  injectSales(backendSales) {
    this.sales = new Sales(
      backendSales.total,
      backendSales.ads,
      backendSales.organic
    );
  }

  injectUnits(backendUnits) {
    this.units = new Units(
      backendUnits.total,
      backendUnits.coupons,
      backendUnits.fullPrice
    );
  }

  injectExpenses(backendExpenses) {
    let _expenses = new Expenses();
    // _expenses.total = backendExpenses.expensesTotal;
    for (const expenseName in backendExpenses) {
      if (expenseName === 'total') continue;
      const _expenseName =
        expenseName === 'coupons'
          ? 'Discounts'
          : expenseNameMap[expenseName]
          ? expenseNameMap[expenseName]
          : expenseName;
      if (typeof backendExpenses[expenseName] == 'number') {
        // is digit
        _expenses.updateExpense(
          _expenseName,
          _expenseName,
          backendExpenses[expenseName]
        );
      } else {
        // is dictionary
        for (const subexpenseName in backendExpenses[expenseName]) {
          if (subexpenseName === 'total') continue;
          if (
            backendExpenses[expenseName][subexpenseName] >= -0.5 &&
            backendExpenses[expenseName][subexpenseName] <= 0.5 &&
            expenseName !== subexpenseName
          )
            continue;
          const _subexpenseName = expenseNameMap[subexpenseName]
            ? expenseNameMap[subexpenseName]
            : subexpenseName;
          _expenses.updateExpense(
            _expenseName,
            _subexpenseName,
            backendExpenses[expenseName][subexpenseName]
          );
        }
      }
    }
    _expenses.finalPreparation(this.sales.total);
    this.expenses = _expenses;
  }

  injectKpis(backendKpis) {
    this.kpis = new Kpis(
      backendKpis.refundsQuantity,
      backendKpis.unitsQuantityNonZeroSales,
      backendKpis.shipping
    );
  }
}

export { Product };
