import { getPrettyInteger } from '@/common/helpers/helpers.js';
import { Subexpense } from '@/store/classes/subexpense';

class Subexpenses {
  constructor() {
    this.dict = {};
    this.list = [];

    this.total = 0;
  }

  /* * * * * * * GETTERS * * * * * * * * */
  get total() {
    return this._total;
  }
  get totalPretty() {
    return getPrettyInteger(this.total);
  }

  get list() {
    return this._list;
  }
  get dict() {
    return this._dict;
  }
  /* * * * * * * * * * * * * * * * * * * */

  /* * * * * * * SETTERS * * * * * * * * */
  set total(val) {
    this._total = val;
  }
  set list(val) {
    this._list = val;
  }
  set dict(val) {
    this._dict = val;
  }
  /* * * * * * * * * * * * * * * * * * * */

  insert(name, total) {
    const subexpenseToAdd = new Subexpense(name, total);

    if (this.dict[name]) this.dict[name].addAssign(subexpenseToAdd);
    else this.dict[name] = subexpenseToAdd;

    this.total += total;
  }

  createByName(name) {
    this.dict[name] = new Subexpense(name);
  }

  getSubexpense(name) {
    return this.dict[name] ? this.dict[name] : new Subexpense();
  }

  finalPreparation() {
    this.collectionDictToList();
    this.sort();
  }

  collectionDictToList() {
    this.list = [];
    for (let subexpenseName in this.dict) {
      this.list.push(this.dict[subexpenseName]);
    }
  }

  sort() {
    this.list = this.list.sort((a, b) => {
      return a.total > b.total ? -1 : 1;
    });
  }
}

export { Subexpenses };
