<template>
  <div class="page-container">
    <sidebar-nav></sidebar-nav>
    <v-container class="py-13">
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <router-view></router-view>
      </fade-transition>
    </v-container>
  </div>
</template>

<script>
import SidebarNav from '@/modules/DashboardLayout/SidebarNav/SidebarNav.vue';
import { FadeTransition } from 'vue2-transitions';
import { mapGetters } from 'vuex';

export default {
  name: 'DashboardLayout',
  props: {
    pageFluid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initialized: false,
    };
  },
  mounted() {
    this.loadReports();
  },
  components: {
    SidebarNav,
    FadeTransition,
  },
  computed: {
    ...mapGetters({
      currentSellerId: 'currentSellerId',
    }),
  },
  watch: {
    currentSellerId(oldId, newId) {
      if (oldId === newId) return;
      this.loadReports();
    },
  },
  methods: {
    loadReports() {
      if (!this.currentSellerId) {
        return;
      }

      this.$store.dispatch('customReports/load_reports', {
        sellerId: this.currentSellerId,
      });
    },
  },
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';
/* TODO: 425 */
.theme--light.v-application {
  background-color: var(--main-background-color) !important;
}

.section-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: var(--universal-padding);
}
.section-header-text {
  width: 100%;
  height: 50px;

  font-family: 'Rajdhani-Bold';
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0.02em;
  text-align: left;

  color: #e1e4f0;
}
</style>
