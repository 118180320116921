function putSpaces(number) {
  let i = number.length - 3;
  for (; i > 0; i -= 3) {
    number = number.substr(0, i) + ' ' + number.substr(i);
  }
  return number;
}
function getPrettyInteger(num) {
  let numRound = num.toFixed(0);

  if (num > 0 && numRound.length >= 4) {
    return putSpaces(numRound);
  } else if (num < 0 && numRound.length >= 5) {
    return '-' + putSpaces(numRound.substr(1));
  } else {
    return numRound;
  }
}

function getPercent(a, b) {
  return b ? (a / b) * 100 : 0;
}

function allowedAdjustment(type) {
  return (
    type !== 'ReserveCredit' &&
    type !== 'ReserveDebit' &&
    !type.includes('Transfer of funds unsuccessful')
  );
}

const expenseNameMap = {
  sellableReturnsCogs: 'Sellable Returns COGS',
  adjustments: 'Adjustments',
  adjustmentsByType: 'Adjustments',
  advertising: 'Advertising',
  cogs: 'Cost of Goods',
  cogsTotal: 'Cost of Goods',
  coupons: 'Coupons',
  promotions: 'Promotions',
  couponRedemptionFee: 'Coupon Redemption Fee',
  fbaFee: 'FBA Fee',
  fulfillment: 'Fulfillment Fees',
  polybaggingFee: 'Polybagging Fee',
  labelingFee: 'Labeling Fee',
  referral: 'Referral Fee',
  referralFee: 'Referral Fee',
  referralTotal: 'Referral Fee',
  refunds: 'Refunds',
  refundsTotal: 'Refunds',
  returnsProcessingFee: 'Returns Processing Fee',
  storage: 'Storage Fee',
  storageFee: 'Storage Fee',
  fbalongTermStorageFee: 'FBA Long Term Storage Fee',
  other: 'Other Fees',
  fbaRemovalFee: 'FBA Removal Fee',
  fbaDisposalFee: 'FBA Disposal Fee',
  vineEnrollmentFee: 'Vine Enrollment Fee',
  amazonSubscription: 'Amazon Subscription',
  lightningDealFee: 'Lightning Deal Fee',
  wholesaleLiquidation: 'Wholesale Liquidation',
  vpcCouponFee: 'VPC Coupon Fee',
  vpccouponFee: 'VPC Coupon Fee',
  vpcCouponsFee: 'VPC Coupon Fee',
  vpccouponsFee: 'VPC Coupon Fee',
  sponsoredProducts: 'Sponsored Products',
  sponsoredVideo: 'Sponsored Video',
  sponsoredBrands: 'Sponsored Brands',
  sponsoredDisplay: 'Sponsored Display',
  manualExpensesByDescription: 'Manual Exp.',
  A2ZGuaranteeRecovery: 'Reimbursement - A2Z Guarantee Case',
  Adjustment: 'Adjustment',
  'Adjustment for financial transaction':
    'Adjustment for financial transaction',
  AGSGlobalInboundTransportation: 'AGS Global Inbound Transportation',
  'Amazon Capital Services': 'Amazon Capital Services',
  'Amazon Easy Ship Charges': 'Amazon Easy Ship Charges',
  BalanceAdjustment: 'Balance Adjustment',
  BuyerRecharge: 'Buyer Recharge',
  ChargeBackRecovery: 'Charge Back Recovery',
  CommissionCorrection: 'Commission Correction',
  COMPENSATED_CLAWBACK: 'Reverse Reimbursement',
  CRETURN_WRONG_ITEM: 'Customer Return - Wrong Item',
  CS_ERROR_ITEMS: 'Customer Service - Itemized',
  CS_ERROR_NON_ITEMIZED: 'Customer Service - Non-Itemized',
  'Current Reserve Amount': 'Current Reserve Amount',
  'Customer Return': 'Customer Return',
  'Damaged:Warehouse': 'Inventory Reimbursement - Warehouse Damaged',
  DebtPaymentLevel2Failure: 'Debt Payment Level 2 Failure',
  DisbursementCorrection: 'Disbursement Correction',
  DisposalComplete: 'Disposal Complete Fee',
  DisposalCompleteCGST: 'Disposal Complete CGST - Tax',
  DisposalCompleteSGST: 'Disposal Complete SGST - Tax',
  FailedDisbursement: 'Failed Disbursement',
  'FBA International Freight Duties and Taxes Charge':
    'FBA International Freight Duties and Taxes Charge',
  'FBA International Freight Duties and Taxes Charge Adjustments':
    'FBA International Freight Duties and Taxes Charge Adjustments',
  'FBA International Freight Shipping Charge':
    'FBA International Freight Shipping Charge',
  'FBA International Freight Shipping Charge Adjustments':
    'FBA International Freight Shipping Charge Adjustments',
  FBACustomerReturnPerUnitFee: 'Customer Return - Per Unit Fee',
  FBAInboundTransportationFee: 'FBA Inbound Transportation Fee',
  FBAInboundTransportationProgramFee: 'FBA Inbound Transportation Program Fee',
  FREE_REPLACEMENT_REFUND_ITEMS: 'Refund Customer Return - Not Received',
  HighVolumeListing: 'High Volume Listing Fee',
  INBOUND_CARRIER_DAMAGE: 'Inventory Reimbursement - Carrier Damaged',
  INCORRECT_FEES_ITEMS: 'Incorrect Fees - Itemized',
  INCORRECT_FEES_NON_ITEMIZED: 'Incorrect Fees - Non-Itemized',
  'Inventory Placement Service Fee': 'Inventory Placement Service Fee',
  'Inventory Storage Overage Fee': 'Inventory Storage Overage Fee',
  'Lost:Warehouse': 'Inventory Reimbursement - Warehouse Lost',
  'Manual Processing Fee': 'Manual Processing Fee',
  'Manual Processing Fee Reimbursement': 'Reimbursement - Manual Processing',
  MaunualDisbursement: 'Manual Disbursement',
  MFNPostagePurchaseCompleteCGST: 'MFN Postage Purchase CGST - Tax',
  MFNPostagePurchaseCompleteIGST: 'MFN Postage Purchase IGST - Tax',
  MFNPostagePurchaseCompleteSGST: 'MFN Postage Purchase SGST - Tax',
  MiscAdjustment: 'Misc. Adjustment',
  MISSING_FROM_INBOUND: 'Inventory Reimbursement - Inbound Lost',
  MISSING_FROM_INBOUND_CLAWBACK:
    'Reversal Inventory Reimbursement - Inbound Lost',
  MULTICHANNEL_ORDER_DAMAGED: 'Multi-Channel Fulfillment Order Damaged',
  MULTICHANNEL_ORDER_LATE: 'Multi-Channel Fulfillment Order Late',
  MULTICHANNEL_ORDER_LOST: 'Multi-Channel Fulfillment Order Lost',
  NonSubscriptionFeeAdj: 'Non-Subscription Fee Adjustment',
  OrderCancellationCharge: 'Order Cancellation Charge',
  Other: 'Other',
  'other-transaction': 'Unknown Other Transaction Fee, Contact Seller Support',
  'Paid Services Fee': 'Paid Services Fee',
  'Payable to Amazon': 'Payable to Amazon',
  PAYMENT_RETRACTION_ITEMS: 'Reimbursement Retraction',
  PostageBilling_DeliveryConfirmation:
    'Postage Billing - Delivery Confirmation',
  PostageBilling_FuelSurcharge: 'Postage Billing - Fuel Surcharge',
  PostageBilling_ImportDuty: 'Postage Billing - Import Duty',
  PostageBilling_Insurance: 'Postage Billing - Insurance',
  PostageBilling_Postage: 'Postage Billing - Postage',
  PostageBilling_PostageAdjustment: 'Postage Billing - Postage Adjustment',
  PostageBilling_SignatureConfirmation:
    'Postage Billing - Signature Confirmation',
  PostageBilling_Tracking: 'Postage Billing - Tracking',
  PostageBilling_TransactionFee: 'Postage Billing - Transaction Fee',
  PostageRefund_DeliveryConfirmation: 'Postage Refund - Delivery Confirmation',
  PostageRefund_Insurance: 'Postage Refund - Insurance',
  PostageRefund_Postage: 'Postage Refund - Postage',
  PostageRefund_PostageAdjustment: 'Postage Refund - Postage Adjustment',
  PostageRefund_TransactionFee: 'Postage Refund - Transaction Fee',
  'Premium Placement Service Fee': 'Premium Placement Service Fee',
  PREPFEE_REFUND: 'Prep Service Refund',
  'Previous Reserve Amount Balance': 'Previous Reserve Amount Balance',
  PRIME_WARDROBE_REIMBURSEMENT: 'Prime Wardrobe Reimbursement',
  RE_EVALUATION: 'Reimbursement Re-Evaluation',
  REMOVAL_ORDER_DAMAGED: 'Removal Order - Damaged',
  REMOVAL_ORDER_LOST: 'Removal Order - Lost',
  RemovalComplete: 'Removal Complete Fee',
  RemovalCompleteCGST: 'Removal Complete CGST - Tax',
  RemovalCompleteSGST: 'Removal Complete SGST - Tax',
  ReturnPostageBilling_FuelSurcharge: 'Return Postage Billing - Fuel Surcharge',
  ReturnPostageBilling_OversizeSurcharge:
    'Return Postage Billing - Oversize Surcharge',
  ReturnPostageBilling_Postage: 'Return Postage Billing - Postage',
  ReturnPostageBilling_Tracking: 'Return Postage Billing - Tracking',
  ReturnPostageBilling_TransactionFee:
    'Return Postage Billing - Transaction Fee',
  REVERSAL_REIMBURSEMENT: 'Revesal Reimbrusement',
  'Shipping label purchase': 'Shipping label purchase',
  'Shipping label purchase for return': 'Shipping label purchase for return',
  ShippingServicesRefund: 'Shipping Services Refund',
  'Storage Fee': 'Storage Fee',
  'Storage Fee Tax': 'Storage Fee Tax',
  StorageBillingCGST: 'Storage Billing CGST - Tax',
  StorageBillingSGST: 'Storage Billing SGST - Tax',
  StorageRenewalBilling: 'Storage Renewal Billing',
  StorageRenewalBillingCGST: 'Storage Renewal Billing CGST - Tax',
  StorageRenewalBillingSGST: 'Storage Renewal Billing SGST - Tax',
  'Subscription Fee': 'Subscription Fee',
  SubscriptionFeeCorrection: 'Subscription Fee Correction',
  'Successful charge': 'Successful charge',
  'Tax Withholding Reimbursement': 'Tax Withholding Reimbursement',
  toRestoreSettlementBalance: 'Restore Settlement Balance',
  'Unplanned Service Fee - Barcode cannot be scanned':
    'Unplanned Service Fee - Barcode cannot be scanned',
  'Unplanned Service Fee - Barcode label missing':
    'Unplanned Service Fee - Barcode label missing',
  WAREHOUSE_DAMAGE: 'Warehouse - Damaged Auto',
  WAREHOUSE_DAMAGE_EXCEPTION: 'Warehouse - Damaged Manual',
  WAREHOUSE_LOST: 'Warehouse - Lost Auto',
  WAREHOUSE_LOST_MANUAL: 'Warehouse - Lost Manual',
  WarehousePrep: 'Warehouse Prep Fee',
  WarehousePrepCGST: 'Warehouse Prep CGST - Tax',
  WarehousePrepSGST: 'Warehouse Prep SGST - Tax',
};

export { getPrettyInteger, getPercent, allowedAdjustment, expenseNameMap };
