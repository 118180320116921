<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.6001 7.19996C9.15827 7.19996 8.8001 6.84179 8.8001 6.39996V2.39996C8.8001 1.95814 9.16032 1.59396 9.59772 1.65634C12.0522 2.00635 13.9937 3.94787 14.3437 6.40234C14.4061 6.83974 14.0419 7.19996 13.6001 7.19996H9.6001Z"
      class="app-fill-current"
    />
    <path
      d="M6.40247 3.25634C6.83988 3.19396 7.2001 3.55814 7.2001 3.99996V7.99996C7.2001 8.44179 7.55827 8.79996 8.0001 8.79996H12.0001C12.4419 8.79996 12.8061 9.16019 12.7437 9.59759C12.3566 12.3126 10.0221 14.4 7.2001 14.4C4.1073 14.4 1.6001 11.8928 1.6001 8.79996C1.6001 5.97796 3.68748 3.6435 6.40247 3.25634Z"
      class="app-fill-current"
    />
  </svg>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'icon-market-share',
});
</script>
