import VuexPersistence from 'vuex-persist';
import Cookies from 'js-cookie';

export const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    sellers: {
      currentSellerStatus: state.sellers.currentSellerStatus,
      currentSeller: state.sellers.currentSeller,
      sellers: state.sellers.sellers,
    },
    subscriptions: {
      subscriptionStatus: state.subscriptions.subscriptionStatus,
      subscriptionDetails: state.subscriptions.subscriptionDetails,
    },
    dateranges: {
      currentRangeType: state.dateranges.currentRangeType,
      currentRangeDates: state.dateranges.currentRangeDates,
    },
    salesAndProfit: {
      filters: state.salesAndProfit.filters,
    },
    trafficAnalytics: {
      range: state.trafficAnalytics.range,
    },
  }),
});

export const vuexCookie = new VuexPersistence({
  restoreState: key => Cookies.getJSON(key),
  saveState: (key, state) =>
    Cookies.set(key, state, {
      expires: 7,
      secure: true,
      sameSite: 'Strict',
    }),
  modules: ['auth'],
});
